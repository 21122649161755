@tailwind base;
@tailwind components;
@tailwind utilities;

@import "_themeL";
@import "_layout";
@import "_overrides";
@import "_dashboard";
@import "custom";
@import "v2";

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

@import "jsoneditor/dist/jsoneditor.min.css";

* {
  font-family: poppins, sans-serif;
}

body {
  .feather-16 {
    width: 16px;
    height: 16px;
  }
}
