.change-password .p-card {
  @apply shadow-none;
}

.change-password .p-card-content {
  @apply pb-[.625rem] border-b border-b-[#DEE2E6];
}

.p-menubar-root-list {
  .p-menuitem-content {
    >.p-menuitem-link {
      padding: 1.7rem 1rem;
      background-color: transparent;
      border-radius: 0;
      border-bottom: 4px solid transparent;

      &.p-menuitem-link-active,
      &:hover {
        border-bottom: 4px solid #4394d8;
      }
    }
  }
}

.editor {
  .data-nav__header .p-menuitem-text {
    display: flex;
    border: none;
    border-radius: 0;
    justify-content: center;
    height: 5.1667rem;
    flex-direction: column;
    color: grey;
    background-color: #f7f7f7;
    text-align: center;
    padding: .8333rem;
    width: 100%;
  }

  li.p-tabmenuitem {
    border: none;
    border-radius: 0;
    justify-content: center;
    height: 5.1667rem;
    flex-direction: column;
    color: grey;
    background-color: #f7f7f7;
    text-align: center;
    padding: .8333rem;
    font-weight: 600;
    transition: box-shadow .15s;
    margin: 0 0 -1px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    overflow: hidden;

    .p-menuitem-icon {
      margin-right: 0;
      font-size: 1.6667rem;
      margin-bottom: .5rem;
    }

    .p-menuitem-text {
      font-size: .8333rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.25;
    }

    &.p-highlight {
      background-color: #fff;
      color: #4394d8!important
    }

    &:hover {
      background-color: #fff;
      color: #4394d8!important
    }


    &.add-button {
      display: flex;
      border: 2px dotted #cccccc !important;
      margin: .6667rem;
      padding: .3333rem;
      border-radius: .3333rem !important;
      background-color: #fff !important;
      z-index: auto;
      flex-direction: column;
      align-items: center;
      padding: 0.5rem;
      cursor: pointer;

      .p-menuitem-icon {
        margin-bottom: 0.5rem;
        font-size: 1.6667rem;
      }

      .p-menuitem-img {
        display: none;
      }

      .p-menuitem-text {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 4.3333rem;
        padding: 0 0.5rem;
      }

      &:hover {
        background-color: #f7f7f7;
        color: #4394d8!important
      }
    }
  }

}

.editor .headerButton {
  color: black
}

.editor .headerButton:hover {
  color: red
}

/*
.editor .listGrid {
  border: none;
}

.editor {
  tbody > tr > td:first-child {
    background-color: unset;
  }

  .imgHeaderButtonSelected, .headerButtonSelected, .sorterButtonSelected {
    color: black;
  }

  .cellSelectedOver, .cellSelectedOverDark, .cellPendingSelectedOver, .cellPendingSelectedOverDark, .recordSummaryCellSelectedOver, .recordSummaryCellSelectedOverDark {
    color: black;
  }

  .cellSelectedOverDarkAltCol {
    color: black;
  }

  tbody > tr > td:first-child.cellSelected {
    background-color: #5fbaf2;
    color: black;
  }
}
*/
.component {
  .order-details {
    .p-orderlist-list{
      background: #f7f7f7;
    }
  }
  .p-orderlist-item {
    display: contents !important;

    .p-component {
      margin-top: .3125rem;
      margin-top: .3125rem;
    }
  }
}

.p-orderlist-list .p-orderlist-item > .p-menuitem-link{
  height: 65px !important;
}

.publishApp-overlay-panel{
  top: 100px !important;
  left : 970px !important;
  max-width: 100%;
  min-height: auto;
  max-height: 500px;
  overflow: auto;
  width: 450px !important;
}

.p-vmenu .p-orderlist-list{
  overflow-x: hidden;
}

.p-tag-published {
  background-color: #5FBEAC33;
  border: 1px solid #5FBEAC;
  color: #5FBEAC;
}

.p-tag-pro{
  background: #4494D733;
  color: #4494D7;
  border: 1px solid #4494D7
}

.p-tag-expired{
  background: #F86C6C33;
  color: #F86C6C;
  border: 1px solid #F86C6C
}

.p-chips .p-chips-multiple-container .p-chips-token{
  border-radius: 15px !important;
  background: #bae6fd !important;
  color: black;
}

p-chips .p-chips{
  width: 100% !important;
}

p-inputgroupaddon {
  width: 180px !important;
}

p-inputgroupaddon .p-element > div{
  width: 120px !important;
}

// https://smartclient.com/smartclient-latest/isomorphic/system/reference/?id=group..cellStyleSuffixes
.listGrid{
  border:none!important;
  max-width:200px!important;
}
/*
.fibr-cell, .fibr-cellDark, .fibr-cellAltCol, .fibr-cellDarkAltCol {
  font-size: 1.5rem;

  input {
    font-size: 1.5rem !important;
    border-width: 0;
  }
}

.fibr-header-cell, .fibr-header-cellDark, .fibr-header-cellAltCol, .fibr-header-cellDarkAltCol {
  font-size: 1.5rem;
}

.fibr-header-cell:hover, .fibr-header-cellDark:hover, .fibr-header-cellAltCol:hover, .fibr-header-cellDarkAltCol:hover {
  font-size: 1.5rem !important;
}

.fibr-header-cellOver, .fibr-header-cellOverDark, .fibr-header-cellOverAltCol, .fibr-header-cellOverDarkAltCol {
  font-size: 1.5rem !important;
}

.fibr-cellOver, .fibr-cellOverDark, .fibr-cellOverAltCol, .fibr-cellOverDarkAltCol {
  font-size: 1.5rem;
}

.fibr-cellSelectedOver, .fibr-cellSelectedOverDark, .fibr-cellSelectedOverAltCol, .fibr-cellSelectedOverDarkAltCol {
  font-size: 1.5rem !important;
}

.fibr-cellSelected, .fibr-cellSelectedDark, .fibr-cellSelectedAltCol, .fibr-cellSelectedDarkAltCol {
  font-size: 1.5rem !important;

  input {
    font-size: 1.5rem !important;
    border-width: 0;
  }
}

.fibr-cell:hover, .fibr-cellDark:hover, .fibr-cellAltCol:hover, .fibr-cellDarkAltCol:hover {
  font-size: 1.5rem !important;
}

.headerOver, fibr-headerOver {
  color: black;
}
*/

.vScrollEndDisabled, .vScrollEnd, .vScrollEndOver, .vScrollEndDown{
  position: absolute!important;
  display: block!important;
  bottom: 0!important;
}
.vScrollTrack{
  background-color: transparent !important;
}
.sorterButton{
  background-color: #fff !important;
}

.p-dialog-google-sheet .p-dialog-footer{
  padding-bottom: 0 !important;
}

.p-dialog-google-sheet .p-dialog-content .p-datatable-table .p-datatable-tbody .selected{
  background-color: #eff8fd;
}