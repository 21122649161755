@import "primeflex/primeflex.scss";

:root {
  --primaryColor: #4394d8;
  --secondaryColor: #97ddff;
  --fontColor: #ffffff;
}

// 0.0835rem = 1px
// 0.167rem = 2px
// 0,2475rem = 3px
// 0.33rem = 4px
// 0.4175rem = 5px
// 0.5rem = 6px
// 0.667rem = 8px
// 0.833rem = 10px
// 1rem = 12px
// 1.167rem = 14px
// 1.333rem = 16px

$gutter: 0.3rem;
/* You can add custom css to this file */
.language-menu {
  position: absolute;
  right: 0px;
  padding: 10px;
}

.hidden {
  display: none;
}

.active {
  background-color: #58a7ec !important;
  color: white !important;
}

li.active {
  background-color: transparent !important;
}

.filtered {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* ------------------------------------------------------ */

/* General */
html,
body {
  background: #ffffff !important;
  font-size: 12px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100% !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-table {
  display: table !important;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.grid {
  margin: 0;
}

.w-300 {
  width: 300px;
}

.w-260 {
  width: 260px !important;
}

.border-r-grey {
  border-right: 1px solid #cccccc;
}

.full-divider {
  .p-divider {
    margin: 40px 0;
    width: 100vw;
    margin-left: -44px;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  margin: 0 0 0.5rem 0;
  // width: 100%!important;
}

* {
  // transition: all 0.2s;
  font-family: "Poppins";
}

.rounded {
  border-radius: 0.33rem !important;
}

.top-65 {
  top: 65px !important;
}

.img-invert {
  filter: brightness(0) invert(1);
}

.shadow {
  box-shadow: 0 0 7px rgb(0 0 0 / 14%) !important;
}

.version-label {
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 0.833rem;
}

.text-shadow {
  text-shadow: 0 0 1rem #fff, 0 0 1rem #fff !important;
}

.mb-9 {
  margin-bottom: 6rem !important;
}

/* Others */
.grid-nogutter {
  > .col,
  > [class*="col-"] {
    padding: 0.5rem;
  }
}

/* Text Color */
.text-greenlight {
  color: #43d8bd !important;
}

.text-blue {
  color: #4394d8 !important;
}

.text-magenta {
  color: #d8439c !important;
}

.text-orange {
  color: #ffb703 !important;
}

.text-green {
  color: #70af85 !important;
}

.text-purple {
  color: #a452e4 !important;
}

.text-softgrey {
  color: #aaaaaa !important;
}

.text-grey {
  color: #757575 !important;
}

.text-dark-blue {
  color: #084b83 !important;
}

/* Border Color */
.border-greenlight {
  border-color: #43d8bd !important;
}

.border-blue {
  border-color: #4394d8 !important;
}

.border-magenta {
  border-color: #d8439c !important;
}

.border-orange {
  border-color: #ffb703 !important;
}

.border-green {
  border-color: #70af85 !important;
}

.border-purple {
  border-color: #a452e4 !important;
}

/* Background Color */
.bg-softgrey {
  background-color: #f7f7f7 !important;
}

.bg-purple {
  background-color: #a452e4 !important;
  border-color: #a452e4 !important;
}

.bg-magenta {
  background-color: #d8439c !important;
  border-color: #d8439c !important;
}

.bg-green {
  background-color: #2ec1ac !important;
  border-color: #2ec1ac !important;
}

.bg-red {
  background-color: #e76e6e !important;
  border-color: #e76e6e !important;
}

.bg-blue {
  background-color: #3b9ef2 !important;
  border-color: #3b9ef2 !important;
}

.bg-softblue {
  background-color: #e3effa !important;
  border-color: #e3effa !important;
}

.bg-dark-blue {
  background-color: #084b83 !important;
  border-color: #084b83 !important;
}

/* Breadcrumb */
.p-breadcrumb {
  background: transparent;
}

/* Global Input */
.p-inputtext,
.p-inputtextarea,
.p-dropdown,
.p-multiselect {
  border-color: #e2e2e2;
  border-radius: 4px;
  font-family: "Poppins";
  width: 100%;

  &[readonly="true"] {
    background: #ced4da;
    opacity: 0.6;

    + label {
      opacity: 0.6;
    }
  }
}

::-webkit-input-placeholder {
  opacity: 0.5;
}

/* Checkbox */
.field-checkbox,
.field-radiobutton {
  margin-bottom: 0.5rem;
}

/* Color Picker */
.p-colorpicker-preview {
  width: 2.5rem;
  height: 2.5rem;
}

/* Calendar */
.p-calendar {
  position: relative;
  display: flex !important;
  width: auto;
  // height: 2.7rem;
}

/* Input Number */
.p-inputnumber {
  position: relative;
  display: flex !important;
  // height: 2.7rem;
}

.p-inputnumber,
p-inputnumber {
  display: flex !important;
}

.p-inputnumber-full-width {
  width: 100%;
}

/* Input Line */
input.p-inputtext {
  &.line-input {
    border: none !important;
    background: transparent;
    border-bottom: 1px solid #cccccc !important;
    border-radius: 0 !important;
  }
}

/* Input Group */
.p-inputgroup {
  .p-inputgroup-addon {
    background: #ffffff;

    &:last-child {
      border: 1px solid #e2e2e2;
      border-left: none;
    }
  }

  .p-inputmask {
    width: 100%;
  }
}

/* Dropdown */
.p-dropdown {
  &.p-disabled {
    -webkit-user-select: unset;
    user-select: unset;

    .p-dropdown-clear-icon {
      display: none;
    }
  }
}

// .p-disabled,
// .p-component:disabled,
// [disabled] {
//   background-color: #e8e8e8;
//   color: #8a8a8a;
//   border-color: #e2e2e2;
//   opacity: 0.65;
// }

/* Checkbox */
.p-checkbox .p-checkbox-box {
  border-width: 1px;
}

/* Chips */
.p-chips .p-chips-multiple-container .p-chips-token {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  max-width: 100%;
  line-break: anywhere;
}

/* Button */
.p-button {
  // padding: 0.75rem 1.5rem;
  padding: 0.5rem 1.5rem;

  &.p-button-warning {
    color: #ffffff !important;
  }

  &.data-type-btn {
    width: 2.5rem !important;
    padding: 0.35rem !important;
    background-color: #e5e5e5 !important;
  }
}

.p-button.p-button-secondary2,
.p-buttonset.p-button-secondary2 > .p-button,
.p-splitbutton.p-button-secondary2 > .p-button {
  color: #8a8a8a;
  background-color: #ffffff;
  border-color: #ffffff;

  &:enabled:hover {
    background: #e5e5e5;
    color: #8a8a8a;
    border-color: #e5e5e5;
  }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: #8a8a8a;
  background-color: #f7f7f7;
  border-color: #f7f7f7;

  &:enabled:hover {
    background: #e5e5e5;
    color: #8a8a8a;
    border-color: #e5e5e5;
  }
}

.p-button.btn-img-sqr {
  flex-direction: column;
  width: 7rem;

  img {
    width: auto;
    height: 2rem;
    margin: 5px;
  }
}

.material-icons {
  font-family: "Material Icons" !important;
}

.icon-no-label {
  &.p-button-icon-only .p-button-label {
    display: none;
  }
}

.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.5rem;
}

.p-button.p-button-icon-only.p-button-rounded {
  height: 3rem;
}

.btn-float {
  z-index: 1;
  position: fixed;
  bottom: 5.75rem;
  right: 1.5rem;
  width: 4rem !important;
  height: 4rem !important;
  box-shadow: 2px 2px 6px #888888;
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  color: var(--fontColor) !important;

  &.btn-back {
    left: 1.5rem;
  }

  &.btn-cart {
    border-radius: 20% 0 0 20% !important;
    right: 0;
  }

  .pi {
    font-size: 1.5rem;
  }
}

.btn-check-data {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.btn-free-plan {
  border-radius: 4rem;
  border: none;
  background: linear-gradient(200deg, #f24818 0%, #ffb703 100%);
  // background: linear-gradient(200deg, #3B9EF2 0%, #FFB703 100%);
  // background: linear-gradient(200deg, rgba(25,237,186,1) 0%, rgba(225,168,20,1) 100%);
}

.btn-upgrade-pro,
.btn-upgrade-pro:enabled:hover,
.btn-upgrade-pro:focus {
  border-radius: 4rem;
  border: none;
  background: linear-gradient(200deg, #63f1d8 0%, #3b9ef2 100%);
}

.btn-soon {
  border-radius: 4rem;
  border: none;
  background: linear-gradient(200deg, #c8c8c8 0%, #8a8a8a 100%);
}

.p-button-xl {
  height: 4.5rem !important;
  width: 4.5rem !important;
}

/* Select Button Default */
.p-selectbutton .p-button {
  background: #ffffff;
  border-color: #e2e2e2;
  color: #4e555b;

  &.p-highlight {
    background: #4394d8;
    border-color: #4394d8;
    color: #ffffff;
  }

  &:not(.p-disabled):not(.p-highlight):hover {
    background: #4394d8;
    border-color: #4394d8;
    color: #ffffff;
  }

  &.p-highlight:hover {
    background: #4394d8;
    border-color: #4394d8;
    color: #ffffff;
  }
}

/* Button Group / Select Button (eg. Order Summary) */
.option-button {
  &.p-selectbutton {
    display: flex;

    .p-button {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: flex-start;
      text-align: start;
      margin: 4px;
      padding: 1rem 1.5rem;
      border-radius: 4px !important;
      border: 1px solid #e2e2e2 !important;

      .title {
        color: #444444;
        margin-bottom: 0;
        font-weight: 700;
      }

      .sub-title {
        color: #8a8a8a;
        font-size: 0.833rem;
      }

      .price {
        color: #444444;
        font-size: 1.167rem;
        font-weight: 700;

        .p-tag {
          display: none;
        }
      }

      .p-tag-danger {
        display: none;
      }

      &.p-highlight,
      &.p-highlight:hover,
      &:not(.p-disabled):not(.p-highlight):hover {
        background: #e3effa;
        border-color: #4394d8 !important;

        .title {
          color: #4394d8;
        }

        .price {
          .p-tag {
            color: #444444;
            display: initial;

            + label {
              display: none;
            }
          }
        }

        .p-tag-danger {
          display: initial;
        }

        .p-tag-secondary2 {
          display: none;
        }
      }
    }

    &.w-100 {
      .p-button {
        width: 100%;
      }
    }
  }
}

/* Button Group / Select Button (eg. Question Form) */
.multi-select-button {
  &.p-selectbutton {
    .p-button {
      border-radius: 4px !important;
      border: 1px solid #dadada !important;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}

/* Custom Google Button */
.abcRioButton {
  height: 50px;
  width: 100% !important;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  color: #757575;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  border-radius: 4px;
}
.abcRioButtonBlue {
  background-color: #4285f4;
  border: none;
  color: #fff;
}
.abcRioButtonBlue:hover {
  background-color: #4285f4;
}
.abcRioButton:hover {
  box-shadow: 0 0 3px 3px rgb(66 133 244 / 30%);
}
.abcRioButtonBlue .abcRioButtonContentWrapper {
  border: 1px solid transparent;
}
.abcRioButtonContentWrapper {
  height: 100%;
  width: 100%;
}
.abcRioButtonBlue .abcRioButtonIcon {
  background-color: #fff;
  border-radius: 1px;
}
.abcRioButtonIcon {
  float: left;
  padding: 15px;
}
.abcRioButtonContents {
  font-size: 16px;
  line-height: 48px;
  font-weight: 600;
  letter-spacing: 0.21px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: top;
}

/* Card */
.card {
  &.card-static {
    border-radius: 6px;
    box-shadow: none;
  }

  &.summary-card {
    background: left top / contain no-repeat
      url(/assets/images/icon/icon_red-circle.svg);
    background-size: 5.831rem 5.831rem;
    border: unset !important;

    img {
      background: #ffffff;
      border-radius: 100%;
      border: 0.0835rem solid #ffffff;
      height: 3.332rem;
    }
  }
}

/* Table */
.p-datatable {
  &.p-datatable-sm .p-datatable-header {
    padding: 0;
  }

  .p-datatable-header {
    background: inherit !important;
    border: none;
    padding: 0;
  }

  .p-datatable-scrollable-header-box {
    padding-right: 0 !important;
  }
  .p-datatable-scrollable-body {
    overflow-y: unset !important;
  }

  .p-datatable-thead > tr > th,
  .p-datatable-tbody > tr > td {
    border: 0.5px solid #dee2e6 !important;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    border: 1px solid #c8c8c8;
    border-width: 1px 1px 1px 1px;
    font-weight: 700;
    color: #333333;
    background: #f4f4f4 !important;
    transition: background-color 0.2s, box-shadow 0.2s;
  }

  .p-datatable-loading-overlay {
    z-index: 99;
  }

  .p-sortable-column .p-sortable-column-icon {
    margin: 0.5rem 0.33rem;
  }

  // &.table-sticky {
  //   .p-datatable-scrollable-header {
  //     position: sticky;
  //     top: -15px;
  //     z-index: 1;
  //     padding-top: 20px;
  //     background: #ffffff;
  //   }
  //   p-paginator {
  //     position: sticky;
  //     bottom: -15px;
  //     z-index: 1;
  //     padding-bottom: 20px;
  //     background: #ffffff;
  //   }
  // }
}

td {
  word-wrap: break-word;
}
/*
thead > tr > th:first-child {
  width: 50px !important;
  max-width: 50px;
  justify-content: center;
  padding: 1rem 1rem;
}

tbody > tr > td:first-child {
  background: #f4f4f4;
}

tbody > tr > td:first-child {
  width: 50px !important;
  max-width: 50px;
  justify-content: center;
}
*/
// #table-el {
//   max-width: 90vw;

//   table {
//     table-layout: auto;
//   }

//   .p-datatable {
//     .p-datatable-thead > tr > th,
//     .p-datatable-tbody > tr > td {
//       max-width: 200px;
//       min-width: 200px;

//       &:first-child {
//         max-width: 50px;
//         min-width: 50px;
//         text-align: center;
//       }
//     }
//   }

//   // .p-datatable-scrollable-wrapper {
//   //     box-shadow: 0 2px 8px rgb(0 0 0 / 20%)
//   // }
// }

// Table Data Editor
.editor.table-data {
  width: calc(100vw - 80px - 80px - 348px);
  max-width: 90vw;

  .p-datatable {
    .p-datatable-wrapper {
      max-height: calc(100vh - 370px);
      border: 1px solid #dee2e6;

      .p-datatable-table {
        table-layout: auto;

        .p-datatable-thead > tr > th,
        .p-datatable-tbody > tr > td {
          max-width: unset;
          min-width: 150px;
          border: 0.5px solid #dee2e6;

          &:first-child {
            max-width: 50px;
            min-width: 50px;
            text-align: center;
            justify-content: center;
            position: sticky;
            left: 0;
            z-index: 1;

            > * {
              justify-content: center;
            }
          }

          &.no-data {
            max-width: unset !important;
          }

          .p-inputtext,
          .p-inputtext:enabled:focus {
            border: none;
            padding: 0 0.5rem;
            background: transparent;
            box-shadow: none;
          }
        }

        .p-datatable-thead {
          z-index: 2;

          > tr > th {
            .header-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .p-datatable-tbody {
          // tr:first-child > td {
          //   border: 1px solid #dee2e6;
          // }

          tr + tr:last-child {
            position: sticky;
            bottom: 0;
            left: 0;
            box-shadow: 0 0 6px rgb(0 0 0 / 15%);
            z-index: 1;

            td {
              background-color: #f7f7f7;

              > * {
                z-index: 1;
              }

              .p-inputtext:enabled:focus,
              .p-inputtext.p-filled,
              span.output,
              span {
                display: flex;
                width: 100%;
                background-color: #f7f7f7;
              }

              &::before {
                content: "Click here to add data";
                position: absolute;
                color: #c1c1c1;
                padding-left: 0.5rem;
              }

              &:first-child {
                padding: 1.5rem;

                p-tablecheckbox {
                  display: none;
                }

                &:before {
                  content: "\e90d";
                  font-family: "primeicons";
                  font-size: 16px;
                  opacity: 1;
                  padding-left: 0;
                  color: #8a8a8a;
                }
              }

              p-celleditor {
                p-checkbox {
                  background-color: #f7f7f7;
                  width: 100%;
                }
              }

              // .p-button {
              //   width: 100%;
              //   padding: 0.5rem;
              //   color: #c1c1c1;
              //   font-family: "Poppins";
              //   background-color: #f7f7f7;
              //   border-color: #f7f7f7;
              //   margin-left: 0;
              //   justify-content: left;

              //   &::before {
              //     content: "Upload to add data";
              //     margin-right: 0.5rem;
              //   }
              // }
            }
          }

          > tr {
            > th > * {
              width: 100%;
            }
            > td {
              padding: 0.5rem 1rem;
              > * {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
                width: 150px;
                height: 20px;
                // height: 100%;
                // display: flex;
                // align-items: center;
                // width: auto;

                // .p-button-table {
                //   width: auto;
                //   height: auto;
                //   padding: 0;
                //   background: transparent;
                //   border: none;
                //   margin-left: 1rem;
                // }
              }
            }
          }
        }
      }
    }

    .p-datatable-footer {
      background: #ffffff;
      border: none;
      padding: 0.5rem 1rem 0;
    }

    // &.p-datatable-flex-scrollable .p-datatable-scrollable-view {
    //   max-height: calc(100vh - 340px);
    // }

    // .p-datatable-scrollable-body {
    //   background: #f7f7f7;
    // }

    // .p-datatable-scrollable-wrapper,
    // .p-datatable-wrapper {
    //   background: #f4f4f4;
    //   max-height: calc(100vh - 370px);
    // max-height: calc(100vh - 312px);
    // max-height: calc(100vh - 340px);
    // }

    // .p-datatable-scrollable-wrapper {
    //   .p-datatable-tbody {
    //     > tr {
    //       > td {
    //         max-width: unset;
    //         overflow: hidden;
    //         white-space: nowrap;
    //         text-overflow: ellipsis;
    //       }
    //     }
    //   }
    // }
  }

  .p-dropdown .p-dropdown-panel {
    top: -94px !important;
  }
}

// Tree Table
.p-tree {
  border: none;
  padding: 0;

  .p-tree-container .p-treenode .p-treenode-content {
    padding: 0;
  }
}

.p-treetable {
  table {
    table-layout: auto !important;
  }

  .p-treetable-header {
    background: transparent;
    border: none;
    text-align: right;
  }

  .p-treetable-wrapper {
    position: relative;
    overflow: auto;
    overflow-y: unset !important;

    .p-treetable-thead > tr > th,
    .p-treetable-tbody > tr > td {
      max-width: 200px;
      min-width: 200px;
      padding: 1rem 1rem;

      &:first-child {
        max-width: 50px;
        min-width: 50px;
        text-align: center;
      }
    }
  }
}

.menu-title {
  margin: 0;
  align-self: center;
  color: #084b83;
  font-weight: 400;
}

.menu-subtitle {
  color: #444444;
  font-weight: 300;
  margin-bottom: 20px;
  // text-align: center;
}

/* Tag */
.p-tag {
  padding: 0.25rem 0.5rem;

  &.p-tag-secondary {
    background-color: #e5e5e5;
    color: #8a8a8a;
  }

  &.p-tag-secondary2 {
    background-color: #64748b;
    color: #ffffff;
  }

  &.p-tag-success {
    background-color: #2ec1ac !important;
    color: #ffffff !important;
  }

  &.p-tag-warning {
    background-color: #ffb703 !important;
    color: #ffffff !important;
  }

  &.p-tag-danger {
    background-color: #df542f !important;
    // background-color: #d8439c !important;
    color: #ffffff !important;
  }

  &.p-tag-basic {
    background-color: #f7f7f7 !important;
    color: #8a8a8a !important;
    border: 1px solid #dbdcdc !important;
    // background-color: #d1f0eb !important;
    // color: #2ec1ac !important;
    // border: 1px solid #89d9cb !important;
  }

  &.p-tag-expired2 {
    background-color: #808080 !important;
  }

  &.p-tag-pro {
    background-color: #e3effa !important;
    color: #4494d9 !important;
    border: 1px solid #95c1e9 !important;
  }
}

/* Accordion */
.p-accordion {
  .p-accordion-header,
  .p-accordion-header.p-highlight {
    .p-accordion-header-link {
      border-radius: 0 !important;
      padding: 1rem 1.25rem;
      border: none;
      border-bottom: 1px solid #c4c4c4 !important;
      background: #e5e5e5 !important;
      display: list-item;

      .p-accordion-toggle-icon {
        float: right;
        margin-top: 0.25rem;
      }

      .p-accordion-header-text {
        float: left;
      }

      .accordion-icon {
        vertical-align: middle;
        margin-right: 10px;
        height: 16px;
        width: 16px;
      }
    }

    &:hover {
      .p-accordion-header-link {
        border: none !important;
        background: #e5e5e5 !important;
        border-bottom: 1px solid #c4c4c4 !important;
      }
    }
  }

  .p-accordion-tab {
    .p-accordion-content {
      background: #f7f7f7;
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #c4c4c4;
    }
  }
}

/* Accordion Custom */
.acc-usage {
  &.p-accordion {
    list-style: none;

    .p-accordion-header {
      .p-accordion-header-link {
        border: 1px solid #e1e1e1 !important;
        background-color: #ffffff !important;
        border-radius: 0.4rem !important;
        display: flex;
        width: 100%;

        .p-accordion-toggle-icon {
          float: left;
          margin-right: 1rem;
          display: none;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &.p-highlight,
      &.p-highlight:hover {
        .p-accordion-header-link {
          // border-bottom: none !important;
          // border-radius: 0.4rem 0.4rem 0 0 !important;
          padding-bottom: 0 !important;
        }

        .p-accordion-toggle-icon {
          padding-bottom: 1rem;
        }

        .list-item {
          // border-bottom: 1px Solid #E1E1E1;
          padding-bottom: 1rem !important;
        }
      }

      &:hover {
        .p-accordion-header-link {
          border: 1px solid #e1e1e1 !important;
          background-color: #ffffff !important;
          // background-color: #EFF8FD !important;
          // border-color: transparent !important;
          // box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
        }
      }
    }

    .acc-btn-new {
      .p-accordion-header {
        .p-accordion-header-link {
          color: #4394d8 !important;
          background-color: #eff8fd !important;
          border: 1px dashed #4394d8 !important;
        }
      }

      &:hover {
        .p-accordion-header {
          .p-accordion-header-link {
            transition: all 0.2s;
            color: #4394d8 !important;
            background-color: #ffffff !important;
            border: 1px solid #4394d8 !important;
          }
        }
      }
    }

    .p-accordion-content {
      background: #ffffff;
      border: 1px solid #e1e1e1 !important;
      border-radius: 0 0 0.4rem 0.4rem !important;
      border-top: none !important;
      padding-left: 3.5rem;
      display: none;
    }
  }
}

/* List */
.list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    align-self: center;
    width: 54px;
    height: 54px;
    min-width: 54px;
    margin-right: 1.5rem;
  }

  .list-detail {
    flex: 1 1 0;
    overflow: hidden;
    padding: 2px;

    .list-title {
      // white-space: nowrap;
      // overflow: inherit;
      // text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .list-desc {
      font-size: 0.875rem;
      font-weight: 400;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .list-action {
    display: flex;
    flex-direction: column;
  }

  ~ .list-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }
}

/* Progress Bar */
.progress-sm {
  &.p-progressbar {
    height: 0.5rem;

    .p-progressbar-label {
      display: none !important;
    }
  }
}

/* Divider */
.p-divider {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
  }

  &.p-divider-horizontal:before {
    border-top: 1px solid #dee2e6 !important;
  }

  &.p-divider-vertical:before {
    border-left: 1px solid #dee2e6;
  }

  .p-divider-content {
    z-index: 1;
  }
}

/* Progress Spinner */
.progress-spinner-btn {
  filter: brightness(10);
  display: flex;
  width: 5px;
  height: 5px;
  position: absolute;
  top: calc(50% - 0.75rem);
  right: 10px;
}

/* Modal Pop Up Dialog */
.p-dialog {
  border: transparent;
  .p-dialog-header {
    // border: none;

    .p-dialog-title {
      font-weight: 500;
      font-size: 1.5rem;
      color: #084b83;
      margin-left: 5px;
      display: flex;
      align-items: center;

      i {
        margin-right: 10px;
        font-size: 1.5rem;
      }
    }

    .p-dialog-icon {
      height: 20px;
      margin-right: 15px;
    }
  }

  .p-dialog-content {
    color: #444444;
  }

  .p-dialog-footer {
    border-top: none;
    text-align: center;
    padding-bottom: 4rem;

    .p-button {
      .p-button-icon {
        order: 2;
        margin-left: 0.5rem;
        margin-right: 0;
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &.p-confirm-dialog {
    .p-confirm-dialog-message {
      text-align: center;
      width: 100%;
      margin-left: 0;
      padding: 20px 0;
    }
  }

  &.dialog-no-footer {
    .p-dialog-content {
      border-radius: 0px 0px 4px 4px;
      padding: 1rem 2rem;
      // overflow: unset;
    }
  }

  &.footer-default {
    .p-dialog-footer {
      border-top: 1px solid #e9ecef;
      padding: 1rem;
      text-align: right;
    }
  }

  .modal-footer {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background: #ffffff;
    width: 100%;
    padding: 1rem 2rem;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: 1px solid #dee2e6;
  }
}

p-dynamicdialog {
  .p-dialog-mask {
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.15s;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Toast Notification Message */
.p-toast {
  z-index: 2000;
  .p-toast-message .p-toast-icon-close {
    padding: 1rem;
  }
}

/* Message */
.p-message.p-message-info {
  border: solid #004085;
  border-width: 0 0 0 6px;
}

/* Data View */
.p-dataview {
  .p-dataview-header {
    background: transparent;
    border: none;
    padding: 1rem 0;
  }

  .p-paginator-bottom {
    border: none;
  }
}

/* Header / Topbar */
.p-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;
  border-bottom: 1px solid #cccccc;

  .p-menubar {
    background-color: #ffffff;
    padding: 0 1rem;
    height: 55px;
    border-radius: 0;

    .p-menubar-root-list {
      > .p-menuitem {
          > .p-menuitem-link {
          padding: 1.7rem 1rem;
          background-color: transparent;
          border-radius: 0;
          border-bottom: 4px solid transparent;

          &.p-menuitem-link-active,
          &:hover {
            border-bottom: 4px solid #4394d8;
          }
        }
      }
    }

    .p-menubar-end {
      display: flex;

      ui-fibr-profile {
        display: flex;
        align-items: center;
      }
    }
  }

  .display-user {
    display: inline-flex;
    line-height: 25px;
    padding: 5px 0px;
  }
}

.hide-menubar {
  .p-menubar-button {
    display: none;
  }
}

/* Sub Header / Sub Topbar */
.sub-header {
  .p-toolbar.p-component {
    background: #f7f7f7;
    position: fixed;
    top: 56px;
    width: 100%;
    z-index: 3;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
    padding: 0.5rem 1.25rem;
    flex-flow: row;
    height: 54px;
  }

  .p-toolbar-group-left {
    flex-flow: wrap;

    .app-detail {
      display: flex;
      align-items: center;
    }

    .logo > img {
      vertical-align: middle;
      width: 40px;
    }
  }
}

/* Content */
.p-content {
  max-width: none;
  margin: 0;
  padding: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 0;
  // z-index: 1;

  &.content-nosubheader {
    top: 55px;

    .layout-main {
      height: calc(100vh - 98px);
    }
  }

  .side-menu {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .center-content {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    min-height: 200px;
    min-width: 200px;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }

  .layout-main {
    margin: 0;
    padding: 0;
    overflow: auto;
    min-height: 200px;
    height: calc(100vh - 154px);
    // max-width: calc(100vw - 80px);

    &.full {
      max-width: calc(100vw);
    }
  }

  .layout-app {
    display: flex;
    height: 100%;
    width: 100vw;
    overflow-x: auto;
    flex-direction: column;

    .app-container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-positive: 1;
      flex-grow: 1;
      // xtouch-action: none;
    }

    .data-content,
    .preview-content {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }

    .data-table,
    .preview-app {
      overflow: overlay;
      height: calc(100vh - 154px);
      display: block;
    }
  }
}

/* Footer */
.footer {
  // z-index: 0 !important;

  .p-footer {
    position: fixed;
    bottom: 0px;
    border-top: 1px solid #cccccc;
    background-color: #ffffff;
    padding: 1rem 1.25rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    // height: 44px;
  }
}

/* Vertical Menu */
.p-vmenu {
  // display: flex;
  // position: sticky;
  // top: 117px;

  p-tabmenu {
    .p-tabmenu-nav {
      width: 80px;
      height: calc(100vh - 153px);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: #f7f7f7;
      overflow-y: auto;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      transition: width 0.3s;

      .p-tabmenuitem {
        // height: 62px;

        > .p-menuitem-link {
          border: none;
          border-radius: 0;
          justify-content: center;
          height: 62px;
          flex-direction: column;
          color: #808080;
          background-color: #f7f7f7;
          text-align: center;
          padding: 10px;

          .p-menuitem-icon {
            margin-right: 0;
            font-size: 20px;
            margin-bottom: 5px;
          }

          .p-menuitem-text {
            font-size: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            line-height: 1.25;
            // line-height: 2;
          }

          .p-menuitem-img {
            position: absolute;
            bottom: 40%;
            right: 34%;
            background: #f7f7f7;
            height: 14px;
            width: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &:hover,
        &.p-highlight {
          > .p-menuitem-link {
            background-color: #ffffff !important;
            color: #4394d8 !important;
            z-index: auto;

            .p-menuitem-img {
              background: #ffffff;
            }
          }
        }
      }
    }

    &.editor {
      .p-tabmenu-nav {
        .p-tabmenuitem {
          &:first-child {
            position: sticky;
            top: 0;
            z-index: 2;
            // margin-bottom: 8px;
            box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);

            > .p-menuitem-link {
              display: flex;

              .p-menuitem-text {
                font-size: 12px;
              }

              .p-menuitem-img {
                display: none;
              }
            }

            &:hover > .p-menuitem-link {
              color: #808080 !important;
              background-color: #f7f7f7 !important;
              cursor: text !important;
            }
          }
        }
      }

      &.editor-advance {
        .p-tabmenu-nav {
          li.p-tabmenuitem {
            &:nth-child(2) {
              position: sticky;
              top: 5.1rem;
              z-index: 1;
              background: #f7f7f7;
              border-bottom: 1px solid #dee2e6;

              > .p-menuitem-link {
                .p-menuitem-img {
                  display: none;
                }
              }
            }

            &.add-button {
              > .p-menuitem-link {
                display: flex;
                border: 2px dotted #cccccc !important;
                // width: fit-content;
                margin: 8px;
                padding: 4px;
                border-radius: 4px !important;

                .p-menuitem-img {
                  display: none;
                }
              }
            }

            &:nth-last-child(1 of .sticky-table) {
              bottom: 0;
              margin-top: auto;
            }

            &:nth-last-child(2 of .sticky-table) {
              bottom: 63px;
              margin-top: auto;

              + .sticky-table {
                margin-top: unset;
              }
            }

            &:nth-last-child(3 of .sticky-table) {
              bottom: 126px;
              margin-top: auto;

              + .sticky-table {
                margin-top: unset;
              }
            }

            &.sticky-table {
              position: sticky;
              border-top: 1px solid #dee2e6;
              z-index: 1;

              > .p-menuitem-link {
                border: none !important;
                margin: 0;
                width: 100%;
                border-radius: 0 !important;
                background: #eeeeee;

                .p-menuitem-img {
                  display: none;
                }
              }
            }

            // &.user-app {
            //   position: sticky;
            //   bottom: 0;
            //   margin-top: auto;
            //   border-top: 1px solid #dee2e6;
            //   z-index: 1;

            //   > .p-menuitem-link {
            //     border: none !important;
            //     margin: 0;
            //     width: 100%;
            //     border-radius: 0 !important;
            //     background: #eeeeee;

            //     .p-menuitem-img {
            //       display: none;
            //     }
            //   }

            //   &.user-role {
            //     bottom: 63px;

            //     + .user-app {
            //       margin-top: unset;
            //     }
            //   }
            // }
          }
        }
      }
    }
  }

  p-orderList {
    .p-orderlist-list {
      width: 80px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: #f7f7f7;
      overflow-y: auto;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      transition: width 0.3s;
      padding: 0;
      border: 0;
      border-radius: 0;

      .p-orderlist-item {
        // height: 62px;
        display: unset;
        overflow: unset;
        padding: 0;
        font-weight: 600;
        position: unset;

        > .p-menuitem-link {
          display: flex;
          border: none;
          border-radius: 0;
          justify-content: center;
          height: 62px;
          flex-direction: column;
          color: #808080;
          background-color: #f7f7f7;
          text-align: center;
          padding: 10px;
          align-items: center;
          position: relative;

          .p-menuitem-icon {
            margin-right: 0;
            font-size: 20px;
            margin-bottom: 5px;
          }

          .p-menuitem-text {
            font-size: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            line-height: 2;
          }

          &:before {
            content: "\e9b2";
            font-family: "primeicons";
            font-size: 10px;
            color: #cccccc;
            position: absolute;
            top: 24%;
            left: 10%;
            transform: rotate(90deg);
          }
        }
        > .active-page {
          background-color: #ffffff !important;
          color: #4394d8 !important;
          z-index: auto;
        }
        :hover{
          background-color: #ffffff !important;
          color: #4394d8 !important;
        }
      }
    }

    .static-menu {
      .p-orderlist-list {
        height: auto;

        .p-orderlist-item {
          &:first-child {
            position: sticky;
            top: 0;
            z-index: 2;
            // margin-bottom: 8px;
            box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
            padding: 0;
            cursor: text;

            > .p-menuitem-link {
              display: flex;

              .p-menuitem-text {
                font-size: 12px;
              }

              &:before {
                display: none;
              }
            }

            &:hover,
            &.p-highlight {
              > .p-menuitem-link {
                color: #808080 !important;
                background-color: #f7f7f7 !important;
                cursor: text !important;
              }
            }
          }

          &:nth-child(2) {
            position: sticky;
            top: 5.15rem;
            z-index: 1;
            background: #f7f7f7;
            border-bottom: 1px solid #dee2e6;

            &:hover {
              background: transparent !important;
            }

            > .p-menuitem-link {
              display: flex;
              border: 2px dotted #cccccc !important;
              // width: fit-content;
              margin: 8px;
              padding: 4px;
              border-radius: 4px !important;
              z-index: inherit;

              &:before {
                display: none;
              }

              .p-menuitem-text {
                white-space: unset;
                line-height: 1.25;
              }
            }
          }
        }
      }
    }

    .p-orderlist-controls,
    .p-orderlist-header {
      display: none;
    }
  }
  p-picklist{
    .p-picklist-list {
        width: 80px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: #f7f7f7;
        overflow-y: auto;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        transition: width 0.3s;
        padding: 0;
        border: 0;
        border-radius: 0;
        max-height:50px!important;
    
        .p-picklist-item {
            // height: 62px;
            display: unset;
            overflow: unset;
            padding: 0;
            font-weight: 600;
            position: unset;
    
            > .p-menuitem-link {
            display: flex;
            border: none;
            border-radius: 0;
            justify-content: center;
            height: 62px;
            flex-direction: column;
            color: #808080;
            background-color: #f7f7f7;
            text-align: center;
            padding: 8px;
            align-items: center;
            position: relative;
    
            .p-menuitem-icon {
                margin-right: 0;
                font-size: 20px;
                margin-bottom: 5px;
            }
    
            .p-menuitem-text {
                font-size: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                line-height: 2;
            }
    
            &:before {
                content: "\e9b2";
                font-family: "primeicons";
                font-size: 10px;
                color: #cccccc;
                position: absolute;
                top: 24%;
                left: 10%;
                transform: rotate(90deg);
            }
            }
            > .active-page {
            background-color: #ffffff !important;
            color: #4394d8 !important;
            z-index: auto;
            }
            :hover{
            background-color: #ffffff !important;
            color: #4394d8 !important;
            }
        }
    }
  }
}

.p-orderlist-item {
  display: unset;
  overflow: unset;
  padding: 0;
  font-weight: 600;

  > .p-menuitem-link {
    display: flex;
    border: none;
    border-radius: 0;
    justify-content: center;
    height: 62px;
    flex-direction: column;
    background-color: #ffffff;
    color: #4394d8;
    text-align: center;
    padding: 10px;
    align-items: center;

    .p-menuitem-icon {
      margin-right: 0;
      font-size: 20px;
      margin-bottom: 5px;
    }

    .p-menuitem-text {
      font-size: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 2;
    }
  }
}

/* Dropdown Menu */
.p-menuitem {
  &.menu-blue {
    .p-menuitem-link {
      .p-menuitem-icon,
      .p-menuitem-text {
        color: #4394d8 !important;
      }
    }
  }
  &.menu-magenta {
    .p-menuitem-link {
      .p-menuitem-icon,
      .p-menuitem-text {
        color: #d8439c !important;
      }
    }
  }
  &.menu-green {
    .p-menuitem-link {
      .p-menuitem-icon,
      .p-menuitem-text {
        // color: #70AF85 !important;
        color: #56d5e1 !important;
      }
    }
  }
  &.menu-static {
    span {
      display: flex;
      align-items: center;
    }
  }
}

/* Login & Register*/
.login-body {
  display: flex;
  height: 100vh;

  .login-content {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;

    .login-panel {
      width: 30%;
      height: 100%;
      overflow: auto;
      padding: 3.5rem;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: #f7f7f7 !important;
      align-items: initial;

      .login-panel-content {
        // text-align: left;
        width: 100%;
        padding: 0;

        .login-header {
          margin-bottom: 3rem;

          .logo {
            justify-content: unset;
            margin-bottom: 1.75rem;

            img {
              width: auto;
              height: 30px;
            }
          }
        }
      }

      &.right {
        width: 70%;
        align-items: unset;
        padding: 7rem 5rem 2rem;
        background-color: #ffffff !important;
        // padding-top: 10rem;
        // color: #ffffff !important;

        .login-panel-content {
          display: flex;
          flex-direction: column;

          h4 {
            font-size: 2.5rem;
            color: #084b83;
          }

          p {
            font-size: 1rem;
            color: #084b83 !important;
          }

          .logo img {
            height: 25px;
            width: auto;
          }
        }
      }
    }

    .login-panel-sifa {
      width: 50%;
      height: 100%;
      overflow: auto;
      padding: 3.5rem 10rem;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: #f7f7f7 !important;
      align-items: initial;

      .login-panel-content {
        // text-align: left;
        width: 100%;
        padding: 0;

        .login-header {
          margin-bottom: 3rem;

          .logo {
            justify-content: unset;
            margin-bottom: 1.75rem;

            img {
              width: auto;
              height: 40px;
            }
          }
        }

        i {
          // display: inline;
          text-align: right;
          float: right;
          padding-left: 5px;
          padding-right: 5px;
          // position: absolute;
          right: 10px;
          font-size: 16px;
          color: #8a8a8a;
          margin-top: -30px;
        }
      }

      &.right {
        width: 50%;
        align-items: unset;
        padding: 10rem;
        background-color: #ffffff !important;
        // padding-top: 10rem;
        color: #ffffff !important;

        .logo img {
          height: 25px;
          width: auto;
        }
        .login-panel-content {
          display: flex;
          flex-direction: column;

          //   h4 {
          //     font-size: 2.5rem;
          //     color: #fff;
          //   }

          //   p {
          //     font-size: 1rem;
          //     color: #fff !important;
          //   }

          //   .logo img {
          //     height: 25px;
          //     width: auto;
          //   }
        }
      }
    }
  }
}

/* Dashboard Chart */
.card.box {
  border: 1px solid #e1e1e1;
  border-radius: 6px !important;
  box-shadow: none;
  padding: 0.833rem;
  // align-items: center;
  min-width: auto;
  line-break: anywhere;

  .card-header {
    border-bottom: 1px solid #c4c4c4;
    padding: 0;
    margin-bottom: 30px;

    .card-title {
      h3 {
        color: #084b83;
        font-weight: 300;
        margin-bottom: 20px;
        margin-left: 20px;
      }
    }
  }

  &.overview-box {
    h1 {
      font-weight: 400;
      font-size: 32px;
    }

    h2 {
      color: #084b83;
      font-weight: 400;
      font-size: 20px;
    }

    h6 {
      color: #8a8a8a;
      font-weight: 400;
    }

    > div + * {
      text-align: right;
    }

    .img-cont {
      padding: 10px;
      border: 1px solid #cccccc;
      border-radius: 6px;
      margin-right: 20px;
      height: 50px;
      width: 50px;
    }

    .title-cont {
      font-weight: 300;
      color: #444444;
    }
  }
}

/* Project Grid */
.project-dataview {
  .p-dataview-content-na {
    background: center top / contain no-repeat
      url(/assets/images/img_project-list_new.svg);
    height: 550px;
    background-position: center;
  }
}

.project-grid-item {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 0px 15px;
  background: #ffffff;
  cursor: pointer;

  .item-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 23px;
  }
}

/* Support Grid */
.support-dataview {
  background: bottom right/contain no-repeat url(/assets/images/img_support.svg);
}

// Billing Grid
// .billing-dataview {
//   background: bottom center/contain no-repeat
//     url(/assets/images/img_billing.svg);
//   border: 1px solid transparent;
// }

.billing-display {
  margin-top: 4vh;
}

.billing-table {
  .p-datatable {
    .p-datatable-wrapper {
      .p-datatable-table {
        .p-datatable-thead > tr > th,
        .p-datatable-tbody > tr > td {
          &:last-child {
            position: sticky;
            right: 0;
            background-color: #ffffff;
          }
          &:nth-last-child(2) {
            position: sticky;
            right: 116px;
            background-color: #ffffff;
            box-shadow: -3px 0px 3px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}

/* Sidebar Page Option */
.page-option.p-sidebar {
  width: 35rem;
  height: calc(100vh - 209px);
  position: fixed;
  right: calc(80px + 312px);
  padding: 0;
  background: #efefef;
  border: none;
  top: 110px;
  // top: inherit;
  border-right: 1px solid #dee2e6;
  z-index: 2 !important;

  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    padding: 0;
  }

  + .p-sidebar {
    right: calc(80px + 312px + 35rem);
    z-index: 4 !important;

    .p-card {
      .p-card-header {
        box-shadow: inset 0 6px 9px -6px rgba(0, 0, 0, 0.25),
          0px 2px 8px rgba(0, 0, 0, 0.05);
      }
    }
  }

  + .p-component-overlay.p-sidebar-mask {
    background: transparent !important;
    z-index: 3 !important;
  }
}

.page-option {
  .p-card {
    box-shadow: none;
    background: #efefef;
    border-radius: 0;

    .p-card-header {
      padding: 1rem;
      position: sticky;
      top: 0;
      z-index: 3;
      background: #efefef;
      box-shadow: 0px 2px 8px rgb(0 0 0 / 5%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 62px;

      .page-header {
        display: flex;
        align-items: center;

        .title-text {
          font-size: 1.333rem;
          font-weight: 600;
          color: #084b83;
          margin-right: 0.5rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 20rem;
        }
      }

      .right-action {
        float: right;
      }
    }

    .p-card-body {
      padding: 0;

      .p-card-content {
        padding-top: 0;
        padding-bottom: 68px;
      }

      .p-card-footer {
        bottom: 44px;
        position: fixed;
        background: #e5e5e5;
        width: 35em;
        padding: 1rem;
        text-align: center;
        z-index: 2;
        box-shadow: 0px -2px 6px rgb(0 0 0 / 10%);
      }
    }

    &.card-more {
      border-radius: 6px;

      .p-card-body {
        .p-card-content {
          padding: 10px;
        }
      }
    }
  }

  &.hide-header {
    .p-card-content {
      padding: 0 !important;

      .grid.mt-2,
      p-divider {
        display: none;
      }
    }
  }
}

.p-card {
  &.card-grid {
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid #e1e1e1;

    .p-card-header {
      position: relative;
      z-index: auto;
      top: unset;
      background: transparent;
      padding: 0;
      height: 150px;

      img {
        border-radius: 10px 10px 0 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        text-align: center;
        overflow: hidden;
        background: #eff8fe;
      }
    }

    .p-card-title {
      font-size: 14px;
      font-weight: 500;
      color: #444;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}

.icon-pack {
  background: #efefef;
  margin: 8px;
  border-radius: 8px;
  padding: 8px;

  .icon-preview {
    height: 80px;
    width: 80px;
    background-color: #f7f7f7;
  }

  .icon-list {
    .p-scrollpanel {
      .p-scrollpanel-content {
        height: 90px;
        width: 100%;
        padding: 0;

        a {
          color: #444444;
        }
      }
    }
  }
}

.comp-temp {
  .comp-list {
    .comp-icon {
      background: #fff;
      padding: 0.833rem;
      border-radius: 0.833rem;

      &.filtered {
        background: #efefef;
      }
    }

    &:hover {
      .comp-icon {
        background: #fff;
      }

      .filtered {
        filter: none;
      }
    }
  }
}

.label-info {
  display: flex;
  align-items: center;
  // margin-bottom: 0.5rem;

  i {
    margin-left: 6px;
  }
}

.overlay-info {
  background: #ffffff;
  border: 0;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  max-width: 250px;
  border-radius: 0.833rem;

  .p-overlaypanel-content {
    padding: 0;
  }

  &::before,
  &::after {
    display: none;
  }

  .p-card {
    box-shadow: none;
    border-radius: 0.833rem;
    background-color: #f9f9f9;

    img {
      border-start-start-radius: 0.833rem;
      border-start-end-radius: 0.833rem;
    }

    .p-card-title {
      font-size: 1.3rem;
      font-weight: 500;
      color: #444444;
    }

    .p-card-body {
      padding-top: 0.6rem;
    }
  }

  &.overlay-dropdown {
    padding: 1rem;
    width: 170px;
    max-height: 300px;
    overflow: auto;
  }
}

/* Mobile: General */
// .sticky-phone {
// position: sticky;
// top: 9rem;
// }

/* Mobile: Phone Screen */
.smartphone,
.iphone {
  position: relative;
  width: 22rem;
  height: 42rem;
  margin: auto;
  border: 1rem #e5e5e5 solid;
  // border-top-width: 60px;
  // border-bottom-width: 60px;
  border-radius: 3.332rem;
  background: #ffffff;

  // The horizontal line on the top of the device
  // &:before {
  //   content: "";
  //   display: block;
  //   height: 2rem;
  //   position: absolute;
  //   top: 0px;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   background: #e5e5e5;
  //   border-radius: 25px;
  //   z-index: 2;
  // }

  // The screen (or content) of the device
  .content {
    // width: 360px;
    // height: 35rem;
    // margin: 25px 0px 0px 4px;
    background: #f7f7f7;
    width: 100%;
    overflow: auto;
    height: 100%;
    border-radius: 2.32rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

/* Android */
.smartphone {
  &:before {
    width: 2rem;
  }
}

/* Iphone */
.iphone {
  &:before {
    width: 10rem;
  }
}

/* Mobile: Tab Preview Switch */
.tab-preview .p-tabmenu.p-component {
  .p-tabmenu-nav {
    border-radius: 10px;
    border: 1px solid transparent !important;
    background: #f7f7f7;
    padding: 6px 4px;

    .p-tabmenuitem {
      .p-menuitem-link {
        border: none;
        border-radius: 6px;
        margin: 0 2px;
        color: #c4c4c4;
        padding: 10px;
        background: inherit;

        .p-menuitem-icon {
          margin-right: 0;
        }
      }

      &.p-highlight {
        .p-menuitem-link {
          color: #8a8a8a;
          background: #ffffff;
        }
      }
    }

    a:focus {
      z-index: 0;
    }
  }
}

/* Mobile: Header Page */
.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  height: 3.7485rem;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 5%);
  vertical-align: middle;
  background: var(--primaryColor);
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  // justify-content: space-between;

  .left-comp {
    display: flex;
    max-width: 15.5rem;
    align-items: baseline;

    &.detail-width {
      max-width: 13.5rem;
    }

    .title-page {
      // padding: 0 20px;
      margin-bottom: 0;
      font-size: 1.333rem;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--fontColor);
    }
  }

  .right-comp {
    display: flex;
    justify-content: right;
    position: absolute;
    right: 0.5rem;
  }

  .p-button-icon-only {
    max-width: fit-content;
    max-height: fit-content;
    padding: 0;
    margin: 0.35rem;
    color: var(--fontColor) !important;

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      box-shadow: unset !important;
    }
  }
}

/* Mobile: Modal */
.modal-mobile {
  .p-dialog {
    box-shadow: 0px -2px 7px rgb(0 0 0 / 5%);
    width: 100%;
    margin: 0 !important;
    border-radius: 22px 22px 36px 36px;

    .p-dialog-header {
      border-top-right-radius: 22px;
      border-top-left-radius: 22px;
      border: 0;
      font-size: 1rem;
      font-weight: 600;
      color: #444444;
      // padding-bottom: 0.5rem;

      .p-dialog-header-close {
        position: absolute;
        right: 10px;
      }
    }

    .p-dialog-content {
      border-bottom-right-radius: 2.334rem;
      border-bottom-left-radius: 2.334rem;
      // padding-top: 0.5rem;
    }
  }

  .modal-top {
    &.p-dialog {
      height: 3.7485rem;
      box-shadow: none;
      border-radius: 36px 36px 0 0;

      .p-dialog-content {
        padding: 0.3rem 1rem;
        border-radius: 36px 36px 0 0;
        overflow: unset;
        display: flex;
        background-color: var(--primaryColor);

        .p-button-icon-only {
          color: var(--fontColor) !important;
        }

        .clear-search {
          color: #8a8a8a !important;
          background-color: transparent !important;
          border-color: transparent !important;
        }
      }
    }
  }

  .appinfo-logo {
    height: 1.2rem;
  }
}

/* Mobile: Primary Bottom Menu */
.mobile-bottommenu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  height: 4.5815rem;
  box-shadow: 0px -2px 7px rgb(0 0 0 / 5%);
  vertical-align: middle;
  background: #ffffff;
  text-align: center;
  z-index: 1;
  padding: 0 0.5rem;
  overflow: auto;
  flex-flow: nowrap;

  &.bottom-page {
    z-index: 2;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .col {
    width: 20%;
    cursor: pointer;

    * {
      cursor: pointer;
    }
  }

  i {
    font-size: 20px;
    color: #8a8a8a;
  }

  label {
    color: #8a8a8a;
    font-size: 0.833rem;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .text-active {
    color: var(--primaryColor);
  }

  .p-badge-dot {
    min-width: 0.667rem !important;
    height: 0.667rem !important;
  }
}

/* Mobile: View on Editor */
.preview-app {
  display: flex;
  align-items: center;

  .mobile-bottommenu {
    position: absolute;
    border-radius: 0px 0px 2.3rem 2.3rem;
  }

  .mobile-header {
    position: absolute;
    border-radius: 2.3rem 2.3rem 0 0;
  }

  .modal-mobile {
    .p-dialog-mask {
      position: absolute;
    }
  }

  .btn-float {
    position: absolute;
  }

  .splash-screen {
    position: absolute;
    border-radius: 2.3rem;
  }

  .camera-container {
    .camera-focus {
      border-width: 14rem 4rem !important;
      border-radius: 2.3rem;
    }

    .qr-scanner {
      video {
        border-radius: 2.3rem;
      }
    }
  }
}

/* Mobile: General Component */
.mobile-comp {
  display: grid;
  padding-top: 4.5815rem;
  padding-bottom: 4.5815rem;

  &.horz {
    overflow-x: scroll;
    display: flex;
    scroll-snap-type: x mandatory;

    > * > * {
      scroll-snap-align: center;
    }

    // > * {
    //   column-gap: 0.833rem !important;
    //   padding: 0 1.167rem 1.167rem !important;
    // }

    .comp-col-1 {
      grid-template-columns: repeat(1000, 16rem) !important;
    }

    .comp-col-2 {
      grid-template-columns: repeat(1000, 12rem) !important;
    }

    .comp-col-3 {
      grid-template-columns: repeat(1000, 8rem) !important;
    }

    .comp-col-4 {
      grid-template-columns: repeat(1000, 4rem) !important;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.mobile-form {
    padding-left: 0.833rem;
    padding-right: 0.833rem;
    line-break: anywhere;

    // .p-fluid {
    //   .field {
    //     &:after {
    //       content: "";
    //       border: 0.01rem solid #dee2e6;
    //       margin: 1rem 0rem;
    //       display: flex;
    //     }
    //   }
    // }
    .field {
      margin-bottom: 1.25rem;
    }

    .p-fluid {
      .field:last-child {
        margin-bottom: 2.7525rem;
      }
    }

    .p-button {
      padding: 0.6rem 1.5rem;

      .p-button-icon {
        filter: brightness(8);
        display: flex;
        font-size: 1.25rem;
        width: 1.25rem;
      }
    }

    .p-float-label label {
      font-size: 1rem;
    }
    // .p-inputtext:enabled:focus,
    // .p-float-label input.p-filled,
    // p-calendar.p-inputwrapper-filled input {
    //   margin-top: 1.5rem;
    // }
    // .p-dropdown {
    //   margin-top: 2.5rem;
    // }
    .p-inputtext:enabled:focus + label,
    .p-float-label input.p-filled ~ label {
      // .p-float-label p-inputnumber.p-inputwrapper-focus ~ label,
      // .p-float-label p-inputnumber.p-inputwrapper-filled ~ label {
      top: 18%;
    }
    p-inputnumber .p-inputtext:enabled:focus {
      margin-top: 0;
    }

    .p-datepicker {
      right: 0.833rem;
    }
    p-calendar.p-inputwrapper-focus,
    p-calendar.p-inputwrapper-filled {
      ~ label {
        margin-top: 1rem;
      }
    }
    .p-inputgroup.upload-field {
      .p-inputtext {
        margin-top: inherit !important;
      }

      .p-fileupload .p-button .p-button-icon {
        margin-right: 0;
      }
    }
    p {
      color: #71787f;

      &.form-label {
        // padding-left: 0.75rem;
        margin-bottom: 0;

        &.form-label-title {
          // margin-bottom: 1.833rem;
          margin-bottom: 1.25rem;
          margin-top: 2rem;
        }
      }
    }

    .p-button {
      font-family: "Poppins";
      display: inline-block;
      background-color: var(--primaryColor);
      border-color: var(--primaryColor);
      color: var(--fontColor);

      .p-button-label {
        display: none;
      }
    }
    a {
      color: var(--primaryColor);
    }

    .p-radiobutton .p-radiobutton-box.p-highlight,
    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: var(--primaryColor);
      background: var(--primaryColor);
    }

    .p-button.p-button-secondary2,
    .p-buttonset.p-button-secondary2 > .p-button,
    .p-splitbutton.p-button-secondary2 > .p-button {
      color: #8a8a8a !important;
      background-color: #ffffff !important;
      border-color: #ffffff !important;
    }

    .camera-container {
      background-color: #000000;

      .mobile-header.camera-header {
        z-index: 4;
        background: transparent;
        box-shadow: none;

        .p-button-icon-only,
        .title-page {
          color: #ffffff !important;
        }
      }

      .camera-focus {
        position: absolute;
        z-index: 3;
        border-width: 13.75rem 4rem;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        inset: 0;

        .camera-desc {
          display: flex;
          position: relative;
          justify-content: center;
          text-align: center;
          top: -4rem;
          color: #cccccc;
        }

        &:before {
          content: "";
          /* make the element cover the whole thing */
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          /* and sit in front */
          z-index: 1;
          /* gradient with a radar like fade */
          background: linear-gradient(
            90deg,
            transparent,
            transparent,
            rgba(255, 255, 255, 0.25) 55%,
            rgba(255, 255, 255, 0.5) 56%,
            transparent,
            transparent
          );
          /* make it big enough so when we slide it around still covers whole image */
          background-size: 300% 100%;
          background-repeat: no-repeat;
          /* start with the scan bar just off the left side */
          background-position: 84% center;
          animation: scan 5s infinite ease;
        }

        // &:after {
        //   content: "";
        //   display: block;
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   z-index: 1;
        //   outline: solid 0.5rem var(--primaryColor);
        //   outline-offset: 0;
        //   border-radius: 1rem;
        // }

        .overlay {
          --border-style: 0.35rem solid var(--primaryColor);
          --border-space: -0.15rem;

          .overlay-element {
            position: absolute;
            z-index: 1;
            width: 4rem;
            height: 4rem;
          }

          .top-left {
            border-left: var(--border-style);
            border-top: var(--border-style);
            top: var(--border-space);
            left: var(--border-space);
            border-top-left-radius: 0.5rem;
          }

          .top-right {
            border-right: var(--border-style);
            border-top: var(--border-style);
            top: var(--border-space);
            right: var(--border-space);
            border-top-right-radius: 0.5rem;
          }

          .bottom-left {
            border-left: var(--border-style);
            border-bottom: var(--border-style);
            bottom: var(--border-space);
            left: var(--border-space);
            border-bottom-left-radius: 0.5rem;
          }

          .bottom-right {
            border-right: var(--border-style);
            border-bottom: var(--border-style);
            bottom: var(--border-space);
            right: var(--border-space);
            border-bottom-right-radius: 0.5rem;
          }
        }

        @keyframes scan {
          /* finish with the scan bar just off the right side */
          100% {
            background-position: 25% center;
          }
        }
      }

      .qr-scanner {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .origin-overlay {
          span {
            font-size: 1rem !important;
            font-family: Poppins, sans-serif !important;
            color: var(--primaryColor) !important;
          }
        }

        canvas {
          height: inherit;
        }
      }
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
      color: #b5babd;
    }

    .field-checkbox,
    .field-radiobutton {
      // margin-bottom: 1.833rem;
      margin-bottom: 1.25rem;
      align-items: start;

      &:last-child {
        margin-bottom: 2rem;
      }

      > label {
        color: #495057;
        line-height: 1.25;
      }
    }

    .form-editor {
      .p-editor-container .p-editor-content.ql-snow {
        min-height: 120px;
        background: #ffffff;

        .ql-editor {
          line-break: anywhere;
        }
      }
    }

    sub.text-magenta {
      vertical-align: baseline;
      margin-left: 0.167rem;
    }
  }
}

.container-info {
  margin-bottom: 0 !important;

  .detail-info {
    justify-content: space-between;
    display: flex;

    .page-info > a {
      color: var(--primaryColor);
    }
  }
}

.profile-card,
.container-info {
  margin: 4.5815rem 0.833rem 0.833rem;

  + .mobile-comp {
    padding-top: 0;
  }

  + .container-info {
    margin-top: 0;
  }
}

.comp-col {
  display: grid;
  // padding: 0 0.0835rem;
  column-gap: 0.0835rem;

  > * {
    margin-bottom: 0.0835rem;
  }

  &.comp-col-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  &.comp-col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.comp-col-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.comp-col-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.loose {
    padding: 0 0.833rem;
    column-gap: 0.833rem;
    > * {
      margin-bottom: 0.833rem;
    }
  }

  &.rounded {
    > * {
      border-radius: 0.667rem;

      .top-left-label {
        .label-text {
          border-bottom-right-radius: 0.667rem;
        }
      }

      .top-right-label {
        .label-text {
          border-bottom-left-radius: 0.667rem;
        }
      }

      .top-center-label {
        .label-text {
          border-bottom-right-radius: 0.667rem;
          border-bottom-left-radius: 0.667rem;
        }
      }
    }
  }

  &.outline {
    > *::after {
      content: "";
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0%;
      width: 100%;
      height: 100%;
      border-radius: 0;
      border: 0.0835rem solid rgba(150, 150, 150, 0.12);
      box-sizing: border-box;
    }

    &.rounded > *::after {
      border-radius: 0.667rem;
    }
  }

  &.float {
    > * {
      box-shadow: rgb(0 0 0 / 15%) 0 0 0.5rem;
    }
  }

  &.group-list {
    margin: 0 0.833rem 0.833rem;
    padding: 0.33rem 0;
    border-radius: 0.667rem;
    box-shadow: rgb(0 0 0 / 15%) 0.2475 0.2475rem 0.833rem;
    background-color: #ffffff;

    > * {
      margin-right: 0.833rem;
      margin-left: 0.833rem;
    }

    > * {
      &::after {
        content: "";
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0%;
        width: 100%;
        height: 100%;
        border-radius: 0;
        border-bottom: 0.0835rem solid rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
      }

      &:last-child::after {
        border-bottom: none;
      }
    }
  }

  &.summary-group-list {
    &.comp-col > *,
    .comp-col > * {
      margin-bottom: 0;
    }

    &.total-bottom {
      > .total-summary-group {
        order: 2;
        bottom: 4.6rem;
        top: unset;
      }
    }

    .total-summary-group {
      position: sticky;
      position: -webkit-sticky;
      top: 3.75rem;
      z-index: 1;
      border-top: 1px solid #ffffff;

      .card-content,
      .ava-detail {
        background: var(--primaryColor);
        color: var(--fontColor) !important;
      }
    }

    .summary-group-container {
      .summary-group {
        background: #ffffff;

        .title-summary-group {
          .card-content,
          .ava-detail {
            background: var(--secondaryColor);
            color: var(--fontColor) !important;
          }
        }

        .summary-subgroup-list {
          .title-summary-subgroup {
            .card-content {
              background: #f7f7f7;
            }
          }

          .summary-subgroup {
            > * {
              border-bottom: 0.04rem solid #efefef !important;

              &:last-child {
                border: 0;
              }
            }
          }
        }
      }
    }

    .card-content
      .card-detail
      .detail-right
      .avatar
      > .card-ava-container:last-child {
      min-width: fit-content;
      text-align: right;
    }

    &.card-group {
      padding: 0 0.833rem;

      &.total-bottom {
        > :first-child {
          order: 2;
          bottom: 5.5rem;
          top: unset;
        }
      }

      .total-summary-group {
        top: 4.5rem;
        border-top: none;
        border-radius: 0.667rem;
        // margin-bottom: 0.833rem;
        box-shadow: rgb(0 0 0 / 15%) 0 0 0.5rem;
      }

      .summary-group-container {
        .summary-group {
          padding-bottom: 0.5rem;
          margin-bottom: 0.833rem;
          // border-radius: 0.667rem;
          // box-shadow: rgb(0 0 0 / 15%) 0 0 0.5rem;
          background: transparent;

          .title-summary-group {
            border-top-left-radius: 0.667rem;
            border-top-right-radius: 0.667rem;
          }

          > * {
            box-shadow: rgba(0, 0, 0, 0.15) 0 0 0.5rem;
          }
        }
      }
    }
  }

  &.align-right {
    .card-header,
    .card-title,
    .card-desc {
      text-align: right;
    }
  }

  &.align-center {
    .card-header,
    .card-title,
    .card-desc {
      text-align: center;
    }
  }

  &.text-lower {
    .card-title,
    .ava-title {
      text-transform: lowercase !important;
    }
  }

  &.text-upper {
    .card-title,
    .ava-title {
      text-transform: uppercase !important;
    }
  }

  &.fs-md {
    .card-title {
      font-size: 1.167rem !important;
      line-height: 1.6rem !important;
    }
    .card-desc {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }

  &.fs-lg {
    .card-title {
      font-size: 1.333rem !important;
      line-height: 1.7rem !important;
    }
    .card-desc {
      font-size: 1.167rem;
      line-height: 1.6rem;
    }
  }

  &.shape-xs {
    .card-img {
      padding-bottom: 33.3333% !important;
    }
  }

  &.shape-sm {
    .card-img {
      padding-bottom: 66.6666% !important;
    }
  }

  &.shape-md {
    .card-img {
      padding-bottom: 99.9999% !important;
    }
  }

  &.shape-lg {
    .card-img {
      padding-bottom: 133.3333% !important;
    }
  }

  &.shape-xl {
    .card-img {
      padding-bottom: 166.6666% !important;
    }
  }
}

.btn-back-top {
  position: sticky;
  position: -webkit-sticky;
  top: 3.75rem;
  z-index: 1;
  background: #f7f7f7;
}

.mobile-print {
  zoom: 46.75%;
  box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 1rem;

  .comp-col {
    &.summary-group-list {
      .total-summary-group {
        position: unset !important;
        position: unset !important;
      }
    }
  }
}

.p-picklist {
  flex-direction: column;

  .p-picklist-source-controls{
    display: none !important;
  }
  .p-picklist-transfer-buttons {
    display: none !important;
  }
  .p-picklist-target-controls {
    display: none !important;
  }
  .p-picklist-list-wrapper{
    width: 80px;
  }
  .p-picklist-list{
    background-color: #f7f7f7;
  }
  .p-picklist-header{
    padding: 5px;
    border: none !important;
    background-color: #f7f7f7;
    .p-picklist-title{
      display:flex;
      text-align:center;
      font-size: 10px;
    }
  }
}

.p-picklist-listbox {
  width: 100% !important;
  margin-bottom: 1rem;
}

// @media print {
//   .mobile-print {
//     zoom: 50%;

//     .comp-col {
//       &.summary-group-list {
//         .total-summary-group {
//           position: unset !important;
//           position: unset !important;
//         }
//       }
//     }
//   }
// }

.card-img {
  position: relative;

  .img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    color: #3b9ef2;
    font-size: 1.333rem;
    text-align: center;
    overflow: hidden;
    background: #eff8fe;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  padding: 0.833rem;
  color: #444444;
  -webkit-box-flex: 1;
  background: #ffffff;

  .card-title-container {
    &:not(:last-child) {
      margin-bottom: 0.33rem;
    }

    .card-header {
      font-weight: 500;
      font-size: 0.667rem;
      line-height: 1rem;
      font-family: Poppins, sans-serif;
      text-transform: uppercase;
      color: var(--primaryColor);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .card-title {
      font-weight: 400;
      // font-size: 14px;
      // line-height: 1.1875rem;
      text-transform: capitalize;
      font-size: 1rem;
      line-height: 1.2rem;
      font-family: Poppins, sans-serif;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .card-desc {
    font-weight: 400;
    // font-size: 12px;
    // line-height: 1.0625rem;
    font-size: 0.833rem;
    line-height: 1rem;
    color: #999999;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-family: Poppins, sans-serif;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .card-detail {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;

    > :first-child:not(:last-child) {
      // margin-right: 20px;
      margin-left: 0.5rem;
      width: 100%;
    }

    .detail-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      + .detail-right {
        min-width: auto;
        max-width: 20%;
        margin-left: 0.5rem;
      }
    }

    .detail-right {
      display: flex;
      flex-direction: column;
      // align-items: flex-end;

      .avatar {
        align-self: end;
        width: 100%;

        > .ava-img {
          order: 1;
          margin-left: 0.833rem;
        }

        > .card-ava-container {
          margin-left: 0;
        }
      }
    }

    .avatar {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.833rem;
      line-height: 1.0625rem;
      font-family: Poppins, sans-serif;
      justify-content: space-between;
      // pointer-events: none !important;

      > .ava-img {
        width: 2.499rem;
        height: 2.499rem;
        border-radius: 100%;
        object-fit: cover;
        flex-shrink: 0;
        font-size: 1.333rem;
        text-align: center;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primaryColor);
        background: var(--secondaryColor);

        + div {
          margin-left: 0.833rem;
        }
      }

      > div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .card-ava-container {
        display: flex;
        flex-direction: column;

        .ava-title {
          text-transform: capitalize;
          line-height: 1.2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .ava-detail {
          font-size: 0.667rem;
          line-height: 1rem;
          color: #999999;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    > * {
      min-width: 1.666rem;
      flex-shrink: 1;
      -webkit-box-flex: 1;
      flex-grow: 1;
    }

    > * > * {
      max-width: 100%;
    }
  }

  .cart-inputnumber {
    margin-top: 0.5rem;

    .pi {
      font-size: 0.667rem;
    }

    .p-inputtext {
      font-size: 0.833rem;
      text-align: center;
      padding: 0.167rem;
      background: #f7f7f7;
      width: 2rem;
      border-width: 1px 0px !important;

      &:enabled:focus {
        box-shadow: none;
        border-color: #e2e2e2;
      }
    }

    .p-button {
      color: var(--primaryColor);
      background: #f7f7f7;
      border: 1px solid #e2e2e2;

      &.p-button-icon-only {
        width: 1.333rem;
        padding: 0.167rem;
      }
    }
  }
}

.card-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  pointer-events: none;
  overflow: hidden;
  border-radius: inherit;

  .label-overlay {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;

    .label-content {
      display: flex;
      flex-direction: column;
      min-width: 1.666rem;
      flex-shrink: 1;
      -webkit-box-flex: 1;
      flex-grow: 1;

      &.top-left-label {
        align-items: flex-start;
      }

      &.top-right-label {
        align-items: flex-end;
      }

      &.top-center-label {
        align-items: center;
      }

      > * {
        max-width: 100%;
        pointer-events: auto;
      }

      .label-text {
        max-width: 70%;
        font-weight: 500;
        font-size: 0.667rem;
        line-height: 1rem;
        font-family: Poppins, sans-serif;
        text-transform: uppercase;
        padding: 0.167rem 1rem 0.167rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        pointer-events: none !important;
        background-color: var(--primaryColor);
        color: var(--fontColor);
        // z-index: 1;
      }
    }
  }
}

.sticky-bottom {
  position: absolute;
  bottom: 4.6rem;
  width: 100%;
  background-color: var(--primaryColor);
  color: var(--fontColor);
  padding: 0.5rem;
  align-items: center;
}

/* Mobile: List Component */
.card {
  &.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.833rem;
    border-radius: 0.833rem;
    box-shadow: 0 0.0835rem 0.33rem #dddddd;
    margin-bottom: 1rem;

    .card-img {
      width: 3.674rem;
      height: 3.674rem;
      border-radius: 2.505rem;
      margin-right: 0.8rem;
    }

    .card-title {
      margin-bottom: 0.5rem;
    }

    .card-text {
      font-size: 0.65rem;
    }
  }
}

.card-list {
  position: relative;

  &.card-list-default {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .card-detail {
      .avatar {
        > .ava-img {
          width: 3.332rem;
          height: 3.332rem;
        }

        .card-ava-container {
          .ava-title {
            font-size: 1rem;
            line-height: 1.6rem;
          }
          .ava-detail {
            font-size: 0.833rem;
            line-height: 1rem;
          }
        }
      }

      > :first-child:not(:last-child) {
        margin-right: 0.4175rem;
      }

      > * {
        min-width: 3.332rem;
      }
    }

    &.cart-list {
      .avatar {
        width: 100%;

        .ava-img {
          border-radius: 0.33rem;
        }

        .card-ava-container {
          width: 100%;

          .ava-price {
            color: var(--primaryColor);
          }
        }
      }
    }
  }

  &.list-type1 {
    &:nth-child(3n + 1) {
      border-left: 4px solid #ff7b54;
    }
    &:nth-child(3n + 2) {
      border-left: 4px solid #14cfff;
    }
    &:nth-child(3n + 3) {
      border-left: 4px solid #6ed5a1;
    }

    &:last-child {
      margin-bottom: 0.833rem;
    }

    .avatar {
      > .ava-img {
        border-radius: 0.33rem;
      }
    }
  }
}

/* Mobile: Grid Component */
.card-grid {
  position: relative;

  &.card-grid-default {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .card-img {
      width: 100%;
      padding-bottom: 33.3333%;
    }

    .card-content {
      background-color: rgb(255, 255, 255);
      color: #444444;
      flex-grow: 1;
    }
  }

  &.card-grid-overlay {
    .card-img {
      // height: 12rem;
      padding-bottom: 33.3333%;
      overflow: hidden;
      border-radius: inherit;

      .img {
        // background-color: rgb(242, 242, 242);
        border-radius: inherit;
      }
    }

    .card-overlay {
      background: rgba(0, 0, 0, 0.4);

      .card-content {
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-direction: column;
        color: #ffffff;
        background: transparent;

        .card-header,
        .card-desc,
        .avatar .ava-detail {
          color: #ffffff;
        }
      }
    }
  }
}

/* Mobile: Details Component */
.card-list-details {
  .card-content {
    padding: 0.833rem 0;

    .card-detail .avatar {
      display: block;
      width: 100%;

      .card-ava-container {
        padding: 0 1.167rem;

        > * {
          margin-top: 0.5rem !important;
        }

        .ava-title {
          line-height: 1.6rem;
          // padding: 0 1.167rem;
          font-size: 1.167rem;
          font-weight: 600;
          white-space: normal;
          text-overflow: unset;
          overflow-wrap: anywhere;
        }

        .ava-detail {
          // padding: 0 1.167rem;
          line-height: 1.2rem;
          white-space: normal;
          text-overflow: unset;

          &.link {
            color: var(--primaryColor);
          }

          .editor-content-only {
            &.p-editor-container {
              .p-editor-content {
                .ql-editor {
                  font-size: 0.833rem;
                  color: #999999;
                }
              }
            }
          }

          .media-container {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;

            > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }

        .detail-image {
          flex-shrink: 0;
          text-align: center;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 0;
          height: 100%;
          width: 100%;
        }

        hr {
          border-width: 0.0835rem 0 0 0;
          margin: 0.5rem 0;
          // border-width: 4px 0 0 0;
          // margin: 6px 0;
        }

        .btn-details {
          width: 100%;
          display: block;
          margin: 0.5rem 0;
          text-overflow: ellipsis;
          background-color: var(--primaryColor) !important;
          border-color: var(--primaryColor) !important;
        }
      }
    }
  }
}

.avatar-string {
  vertical-align: middle;
  width: 4.175rem;
  height: 4.175rem;
  border-radius: 50%;
  text-align: center;
  background: aliceblue;
  font-weight: bolder;
  font-size: x-large;
  padding: 1.4195rem;
}

// Mobile Calendar
.m-cal {
  .p-calendar .p-datepicker {
    max-width: 143%;
    min-height: 100%;
  }

  .p-datepicker table td {
    padding: 0;

    > span {
      width: 2.35rem;
      height: 2rem;
    }
  }
}

//Mobile Search
.p-inputgroup {
  &.mobile-search {
    border: 1px solid #e2e2e2;
    border-radius: 2rem;
    padding: 0.25rem;
    background-color: #ffffff;

    .p-inputtext {
      border: none;
      border-radius: 2rem;
    }

    .p-button-icon-only,
    .p-button-icon-only:hover {
      border-radius: 2rem;
      height: 2.5rem;
      width: 2.5rem;
      background-color: var(--primaryColor);
      border-color: var(--primaryColor);
      color: var(--fontColor);
    }

    .clear-search {
      position: absolute;
      right: 6.825rem;
      z-index: 1;
    }
  }
}

// Form Inline / Horizontal
.form-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: pre;

  > div {
    padding: 0;
    padding-right: 0.5rem;
    white-space: normal;
  }

  sub {
    display: block;
  }
}

// Left Sidebar & Content Box
.left-sidebar {
  position: fixed;
  overflow: hidden;
  z-index: 2;
  width: 250px;
  // height: 100%;
  background-color: #f7f7f7;

  .menu-logo {
    padding: 15px 10px;
    border-radius: unset;
    text-align: center;
    margin-bottom: 0 !important;
    line-height: 0;
    border-right: 1px solid #dee2e6;
    background-color: #ffffff;
    box-shadow: 0px 1px 6px rgb(0 0 0 / 15%);
    position: sticky;
    z-index: 1;
  }

  .menu-list {
    border-radius: unset;
    padding: 0;
    height: calc(100vh - 219px) !important;
    overflow-y: auto;

    .p-panelmenu {
      .p-panelmenu .p-menuitem-text {
        line-break: anywhere;
        padding-right: 20px;
      }

      .p-panelmenu-header {
        > a {
          border-radius: 0 !important;
          color: #444444;
          font-weight: 400;
          background-color: #f7f7f7;

          .p-panelmenu-icon {
            display: inline;
            text-align: right;
            float: right;
            padding-left: 5px;
            position: absolute;
            right: 10px;
            font-size: 12px;
            color: #8a8a8a;
          }

          &:hover,
          &[aria-expanded="true"] {
            border-color: #dee2e6;
            background-color: #e5e5e5;
            color: #444;
          }
        }
      }

      .p-panelmenu-content {
        padding: 0;

        .p-menuitem .p-menuitem-link {
          padding: 1rem 1rem 1rem 3rem;
          border-radius: 0;
          transition: box-shadow 0.15s;
          -webkit-user-select: none;
          user-select: none;
          background-color: #efefef;

          .p-menuitem-text,
          .p-menuitem-icon {
            color: #444444;
          }

          &.p-menuitem-link-active {
            background-color: #ffffff;

            .p-menuitem-text,
            .p-menuitem-icon {
              color: #084b83;
            }
          }

          &:hover {
            border-color: #dee2e6;
            background-color: #e5e5e5;
            color: #444;
          }
        }
      }
    }
  }

  .left-sidebar-button {
    position: absolute;
    display: flex;
    height: 100%;
    top: 0;
    align-items: center;
    left: 20px;
    cursor: pointer;
  }

  &.hide-sidebar {
    width: 50px;

    .menu-logo {
      padding: 1.75rem;

      img {
        display: none;
      }
    }

    .menu-list {
      height: calc(100vh - 195px) !important;
      overflow-x: hidden;

      .p-panelmenu {
        .p-menuitem-text,
        .p-panelmenu-header > a .p-panelmenu-icon {
          display: none;
        }

        .p-menuitem-link,
        .p-panelmenu-header-link {
          padding: 1.25rem !important;
          .p-menuitem-icon,
          .p-panelmenu-icon {
            margin: auto;
          }
        }

        .p-menuitem-link:hover,
        .p-panelmenu-header-link:hover {
          .p-menuitem-text {
            display: block;
            position: fixed;
            z-index: 1;
            left: 40px;
            width: auto;
            background-color: rgb(73 80 87 / 90%);
            color: #ffffff !important;
            text-align: center;
            border-radius: 4px;
            padding: 5px 10px;
          }
        }
      }
    }

    + .content-box {
      margin-left: 50px;
      width: calc(100vw - 50px);
    }
  }
}

.content-box {
  margin-left: 250px;
  width: calc(100vw - 250px);
  overflow: hidden;
}

// Project Properties
.project-properties {
  top: 110px;
  position: fixed;
  width: calc(100vw - 80px - 261px);
  height: calc(100vh - 12.75em);
  right: calc(80px + 261px);
  border-radius: 0;
  border: 1px solid #cccccc;

  &.p-dialog {
    .p-dialog-header,
    .p-dialog-content {
      border-radius: 0;
    }

    .p-dialog-content {
      height: 100%;
    }

    .p-dialog-footer {
      border-top: 1px solid #e9ecef;
      padding: 1rem;
      text-align: right;
    }
  }
}

// Scrollbar
::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: inherit;
}

::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 25px;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar {
  width: 0;
  height: 7px;
}

// ::-webkit-scrollbar {
//   display: none;
// }

// * {
//   -ms-overflow-style: none;
//   scrollbar-width: none;
//   overflow: -moz-scrollbars-none;
// }

// User Image
.user-image {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  object-fit: cover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

// Card Image
.card-image {
  max-width: 100%;
  max-height: 250px;
  border-radius: 8px;
  object-fit: cover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.videoLocal {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}

.local {
  position: fixed;
  // right: 1rem;
  // bottom: 1rem;
  z-index: 1;
}

.remote {
  position: relative;
  bottom: 390px;
  left: 10px;
  width: 150px;
  height: 200px;
  margin-bottom: -200px;

  > div {
    border-radius: 5px;
  }
}

.unremote {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}

.videoLocal-fullscreen {
  width: 100%;
  // height: 600px;
  height: calc(100vh - 11rem);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  background: #000000;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain !important;
  }
}

.remote-fullscreen {
  position: relative;
  bottom: 390px;
  left: 10px;
  width: 150px;
  height: 200px;
  margin-bottom: -200px;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain !important;
  }
}

.unremote-fullscreen {
  width: 100%;
  // height: 600px;
  height: calc(100vh - 19rem);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  // background: #000000;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain !important ;
  }
}

// Custom Label/Badge
.label-black,
.label-telur-asin,
.label-telur-asin-muda,
.label-biru-muda,
.label-grey,
.label-softgrey,
.label-ungu,
.label-red,
.label-yellow,
.label-green,
button.label.p-button-danger {
  padding: 0.5rem 1.5rem;
  color: white;
  border-radius: 4px;
  border-color: transparent;
  display: flex;
  margin: auto;
  width: fit-content;
  text-align: center;
}

.label-black {
  background: #000000;
}

.label-telur-asin {
  background: #387f88;
}

.label-telur-asin-muda {
  background: #60beac;
}

.label-biru-muda {
  background: #589ceb;
}

.label-grey {
  background: #808080;
}

.label-softgrey {
  background: #f7f7f7;
  color: #a2a2a2;
}

.label-ungu {
  background: #8c81ed;
}

.label-red {
  background: #dc3545;
}

.label-yellow {
  background: #e6cb03;
}

.label-green {
  background: #ffc107;
}

// Tab Data & Preview on Tablet & Mobile
.tab-editor {
  display: none;

  .p-tabmenu .p-tabmenu-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .p-tabmenuitem {
      margin-right: 0;
      width: 50vw;

      .p-menuitem-link {
        height: 60px;
        justify-content: center;
        border-radius: unset;
        width: 100%;
      }

      &.p-highlight .p-menuitem-link {
        background: rgb(0 122 217 / 5%) !important;
        color: #444 !important;
        border-color: #dee2e6;
      }
    }
  }
}

// Tab View Standard
.tabview-standard {
  &.p-tabview {
    .p-tabview-nav {
      display: table;
      width: 100%;

      li {
        margin-right: 0;
        display: table-cell;

        .p-tabview-nav-link {
          border: solid #dee2e6;
          border-width: 0 0 2px 0;
          justify-content: center;
          font-size: 14px;
          padding: 12px;
          background: transparent;

          &:not(.p-disabled):focus {
            box-shadow: none;
          }
        }

        &.p-highlight .p-tabview-nav-link {
          background: #ffffff;
          border-color: #4394d8;
          color: #4394d8;
        }
      }

      .p-tabview-ink-bar {
        display: none;
      }
    }

    .p-tabview-panels {
      background: transparent;
    }
  }

  &.tab-sticky-modal {
    .p-tabview-panels {
      max-height: calc(100vh - 300px);
      overflow: auto;
    }

    .footer-sticky {
      position: absolute;
      left: 0;
      bottom: -5.5rem;
      width: 100%;
      padding: 1.5rem 2.5rem;
      background: #ffffff;
      border-top: 1px solid #dee2e6 !important;
      border-radius: 0 0 4px 4px;
      text-align: right;
    }
  }
}

// Timeline
.p-timeline.p-timeline-vertical {
  .p-timeline-event-opposite {
    display: none;
  }

  .p-timeline-event-content {
    margin-bottom: 20px;
  }
}

.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
}

// Right Toolbar
.right-toolbar {
  top: 35px;
  position: relative;
  align-items: center;
  justify-content: right;
  margin-top: -30px;
  z-index: 1;
}

// Tooltip
.p-tooltip .p-tooltip-text {
  overflow-wrap: anywhere;
}

// Custom Upload
.p-fileupload {
  &.custom-upload {
    .p-fileupload-buttonbar {
      display: none;
    }

    .p-fileupload-content {
      border-radius: 4px;
      padding: 0;

      .p-fileupload-files {
        + * {
          display: none;
        }
      }
    }
  }
}

// Progress Spinner Global
.p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
    custom-progress-spinner-color 6s ease-in-out infinite;
}

// Project Template
.p-card.card-project-template {
  box-shadow: none;
  cursor: pointer;
  // margin: 18px;
  margin: 8px;
  margin-bottom: 0;

  .p-card-header {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 40px;

    .img-template {
      height: auto;
      max-width: 80px;
      width: -webkit-fill-available;
      object-fit: cover;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
  }

  .p-card-body {
    h5 {
      white-space: nowrap;
      overflow: inherit;
      text-overflow: ellipsis;
    }

    .p-card-subtitle {
      font-size: 14px;
      margin: 0;
    }
  }

  &:hover {
    .p-card-header {
      transition: all 0.2s;
      background-color: #eff8fd;
      border-color: transparent;
      box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);

      .img-template {
        background-color: #eff8fd;
      }
    }
  }
}

// Overlay
.p-blockui.p-component-overlay {
  z-index: 3;
}

@keyframes custom-progress-spinner-color {
  100%,
  0% {
    stroke: #81ecd8;
  }
  40% {
    stroke: #62c9e2;
  }
  66% {
    stroke: #5e8bea;
  }
  80%,
  90% {
    stroke: #084b83;
  }
}

//QR Code
.qrcode {
  img {
    width: 100%;
    display: flex;
  }
}

//Splash Screen
.splash-screen {
  animation-name: bg-animation;
  animation-duration: 6s;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  transition: all 0.3s;
  border-radius: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.bg-app {
    animation-name: none;
    animation-duration: none;
    position: absolute;
    visibility: visible;
    opacity: 1;
    z-index: auto;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
    overflow: auto;
    transition: none;
  }

  .img-app {
    max-height: 8rem;
    max-width: 10rem;
    position: absolute;
  }

  .splash-powered {
    background: #ffffff;
    position: absolute;
    bottom: 3rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    text-align: center;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);

    p {
      font-size: 0.6rem !important;
    }

    img {
      height: 1rem;
    }
  }
}

.img-app {
  max-height: 8rem;
  max-width: 8rem;
  position: relative;
  display: flex;
  margin: auto;
  margin-bottom: 4rem;
}

// Pricing Plan Table
.pricing-plan-table {
  .p-datatable-wrapper {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }

  table {
    tr {
      > * {
        width: 33.3%;
        text-align: center !important;
        border-width: 1px 0 !important;
      }

      > td:nth-child(1) {
        color: #444444;
        padding-left: 3rem;
        text-align: left !important;
        font-weight: initial !important;

        &.section-title {
          font-weight: 800 !important;
          padding-left: 1rem;
        }
      }

      > td:nth-child(2) {
        background: #f7f7f7;
        color: #8a8a8a;
      }

      > td:nth-child(3) {
        background: #e3effa;
        color: #3b9ef2;
      }
    }

    tr:first-child {
      position: sticky;
      top: 0;
      box-shadow: 0 0 6px rgb(0 0 0 / 15%);

      > * {
        border: none !important;
        background: #ffffff !important;

        h2 {
          margin: 0;
        }

        small {
          font-size: 1.125rem;
          font-weight: 300;
        }

        p {
          font-size: 0.875rem;
          font-weight: 300;
          margin-bottom: 1.5rem;
          color: #757575;
        }
      }

      // td:nth-child(2) {
      //   background: #F7F7F7;
      //   color: #8A8A8A;
      // }

      > td:nth-child(3) {
        h2 {
          color: #222222;
        }
      }
    }
  }
}

// User Menu
.user-menu {
  .p-listbox {
    .card.box {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    position: sticky;
    top: 3rem;

    .p-listbox-item {
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 1.5rem !important;
      margin-bottom: 1.5rem;
    }

    .p-listbox-item:last-child {
      border-bottom: none;
      padding-bottom: 0 !important;
      margin-bottom: 0;
    }

    &:not(.p-disabled)
      .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
      background: transparent;
      cursor: pointer;
      color: #4394d8 !important;
    }
  }

  > p-tabview > .p-tabview > .p-tabview-nav-container {
    display: none;
  }
}

// Order List
.p-orderlist {
  &.order-details {
    .p-orderlist-controls,
    .p-orderlist-header {
      display: none;
    }
    .p-orderlist-list {
      border: none;
      background: transparent;
      padding: 0;
      min-height: unset;

      .p-orderlist-item {
        border: 1px solid transparent;
        padding: 0;
        overflow: unset;
        position: unset;
        margin-bottom: 2px;

        .p-card {
          &.card-details {
            .p-card-content {
              > .grid {
                flex-wrap: nowrap;

                &:before {
                  content: "";
                  font-family: "primeicons";
                  font-size: 8px;
                  color: #cccccc;
                  display: flex;
                  margin-right: 8px;
                  transform: rotate(90deg);
                }

                > span {
                  overflow: hidden;
                  max-width: 19rem;
                  min-width: 1rem;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  padding-right: 10px;
                }
              }
            }
          }
        }
      }

      &:not(.cdk-drop-list-dragging) .p-orderlist-item {
        &:not(.p-highlight):hover,
        &.p-highlight {
          border: 1px solid #4494d7;
          border-radius: 4px;

          .p-card {
            &.card-details {
              background: #ffffff;
            }
          }
        }
      }
    }
  }
}
.p-orderlist-item.cdk-drag-preview {
  height: auto !important;
  padding: 0;
  border-radius: 4px;
}

// Card Details
.p-card {
  &.card-details {
    border-radius: 4px;

    .p-card-body {
      padding: 0;

      .p-card-content {
        padding: 6px;

        img {
          width: 1.5rem;
          height: 1.5rem;
          padding: 0.2475rem;
          background-color: #ffffff;
          border-radius: 2px;
          margin-right: 6px;
        }

        button {
          margin-left: auto;
          width: auto;
        }
      }
    }
  }
}

// Notification Panel
.p-overlaypanel {
  &.notif-overlay-panel {
    top: 36px !important;
    max-width: 100%;
    min-height: auto;
    max-height: 500px;
    overflow: auto;

    .notif-list {
      display: flex;
      align-items: normal;
      border: none;
      border-bottom: 1px solid #e1e1e1;
      border-radius: 0 !important;
      background-color: #eff8fd;

      .custom-marker {
        position: absolute;
      }

      .notif-detail {
        width: 100%;
        display: block;
        padding: 3px 0 0 36px;
      }
    }

    a:last-child .notif-list {
      border-bottom: none;
    }
  }
}

// Watch Video
.p-galleria .p-galleria-close {
  position: absolute;
  top: 0;
  right: 0;
}

// Project Banner
.project-banner {
  .p-galleria-item {
    // height: 200px;
    // height: 194px;

    a {
      width: 100%;
      height: 100%;
      object-fit: cover;

      .img-banner {
        width: 100%;
        height: 100%;
        display: block;
        // object-fit: cover;
        border-radius: 6px;
      }
    }
  }

  &.mobile-galleria .p-galleria-item {
    height: 120px;
  }

  .p-galleria-item-nav {
    position: absolute;
    height: 100%;
    top: 0;
    // top: 42%;
    // margin-top: -0.5rem;
    // display: inline-flex;
    // justify-content: center;
    // align-items: center;
    // overflow: hidden;
  }
}

// Text Editor
.editor-content-only {
  &.p-editor-container {
    .p-editor-toolbar {
      display: none;
    }

    .p-editor-content {
      border: none;

      .ql-editor {
        padding: 0;
      }
    }
  }
}

// Import GSheet
.import-table-gsheet {
  &.p-datatable {
    &.p-datatable-scrollable {
      .p-datatable-loading-overlay + .p-datatable-wrapper {
        height: 100px;
      }

      .p-datatable-wrapper {
        // max-height: 36rem;
        max-height: calc(100vh - 300px);
      }
    }

    .p-datatable-thead > tr > th:nth-child(2) {
      max-width: 70px;
    }

    .p-datatable-tbody > tr > td:nth-child(2) {
      max-width: 70px;
      justify-content: center;
    }
  }
}

.import-table-gsheet-preview {
  &.p-datatable {
    &.p-datatable-scrollable {
      .p-datatable-wrapper {
        // max-height: 36rem;
        max-height: calc(100vh - 360px);
      }
    }

    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      text-align: left;
      min-width: 150px;
      max-width: unset;
      justify-content: unset;
      overflow-wrap: anywhere;
    }

    .p-datatable-thead > tr > th > div {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .p-datatable-tbody > tr > td:first-child {
      background: #ffffff;
    }
  }
}

.import-tree-gsheet {
  display: flex;
  // max-height: 40rem;
  max-height: calc(100vh - 290px);
  overflow: auto;
}

// Change Password Page
.fibr-portal {
  ui-fibr-change-password {
    ui-fibr-dynamic-form {
      > .grid {
        background: bottom center/contain no-repeat
          url(/assets/images/img_project-list_new.svg);
        height: calc(100% - 44px);

        form.grid {
          padding: 30px 36px;

          .p-toolbar-group-left.mr-3 {
            margin: 0 !important;
          }

          .p-card.card-more {
            background-color: #ffffff !important;

            .p-card-body {
              .p-card-content {
                padding: 0;
                padding-bottom: 10px;
                border-bottom: 1px solid #dee2e6 !important;

                > .grid.mt-2,
                > p-divider {
                  display: none;
                }

                ui-fibr-form-input {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;

                  > p {
                    width: 40%;

                    span {
                      color: #d8439c;
                    }
                  }

                  > div {
                    width: 60%;
                  }

                  > input {
                    width: 60%;
                    height: fit-content;
                  }

                  ui-fibr-validator {
                    width: 100%;
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Question Form
ui-fibr-question {
  display: block;
  position: absolute;
  top: -55px;
  left: 0;
  background: #f7f7f7;
  z-index: 9;
}

// Input Switch
.p-inputswitch .p-inputswitch-slider {
  border-radius: 30px;

  &:before {
    border-radius: 100%;
  }
}

// Messages
.message-flat {
  .p-message {
    border-width: 0;
  }
}

// Product Tour
.shepherd-button {
  margin-right: 0.5rem !important;

  &.p-button.p-button-secondary2 {
    margin-right: auto !important;
  }
}

.shepherd-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem 2rem;

  .shepherd-button:last-child {
    margin-right: 0 !important;
  }
}

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: hsla(0, 0%, 50%, 0.75);
  cursor: pointer;
  font-size: 2em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color 0.5s ease;

  &:hover {
    color: rgba(0, 0, 0, 0.75);
  }
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: hsla(0, 0%, 50%, 0.75);
}
.shepherd-title {
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex: 1 0 auto;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.shepherd-header {
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  padding: 0.75rem 0.75rem 0;
}
.shepherd-has-title .shepherd-content .shepherd-header {
  background: #ffffff;
  padding: 2rem 2rem 0;
}

.shepherd-text {
  color: rgba(0, 0, 0, 0.75);
  font-size: 1rem;
  line-height: 1.3em;
  padding: 2rem;

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.shepherd-content {
  border-radius: 5px;
  outline: none;
  padding: 0;
}

.shepherd-element {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;

  &.shepherd-enabled {
    opacity: 1;
    visibility: visible;
  }

  &[data-popper-reference-hidden]:not(.shepherd-centered) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  &[data-popper-placement^="right"] {
    margin-left: 1rem;
  }

  &[data-popper-placement^="left"] {
    margin-left: -1rem;
  }

  &[data-popper-placement^="top"] > .shepherd-arrow {
    bottom: -8px;
  }

  &[data-popper-placement^="bottom"] > .shepherd-arrow {
    top: -8px;
  }

  &[data-popper-placement^="left"] > .shepherd-arrow {
    right: -8px;
  }

  &[data-popper-placement^="right"] > .shepherd-arrow {
    left: -8px;
  }

  &.shepherd-centered > .shepherd-arrow {
    opacity: 0;
  }

  &.shepherd-has-title[data-popper-placement^="bottom"]
    > .shepherd-arrow:before {
    background-color: #e6e6e6;
  }
}
.shepherd-element,
.shepherd-element *,
.shepherd-element:after,
.shepherd-element:before {
  box-sizing: border-box;
}

.shepherd-arrow {
  height: 16px;
  position: absolute;
  width: 16px;
  z-index: -1;

  &:before {
    height: 16px;
    position: absolute;
    width: 16px;
    z-index: -1;
    background: #fff;
    content: "";
    transform: rotate(45deg);
  }
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;

  &.shepherd-modal-is-visible {
    height: 100vh;
    opacity: 0.5;
    transform: translateZ(0);
    transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
  }

  &.shepherd-modal-is-visible path {
    pointer-events: all;
  }
}

@keyframes bg-animation {
  0% {
    visibility: visible;
    opacity: 1;
  }
  50% {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

// Responsive
@media screen and (min-width: 992px) {
  .mt-min {
    margin-top: -10rem;
  }
}

@media (max-width: 1024px) {
  .login-body {
    .login-content {
      .login-panel {
        .login-panel-content {
          width: 100%;
        }
      }
      .login-panel-sifa {
        .login-panel-content {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  #default-toolbar {
    display: none !important;
  }

  #dropdown-toolbar {
    display: block !important;
  }
}

@media only screen and (min-width: 769px) {
  .data-content,
  .preview-content {
    display: flex !important;
  }

  .fibr-portal {
    ui-fibr-change-password {
      ui-fibr-dynamic-form {
        form.grid {
          > .col-12.page-option {
            width: 51%;
            margin: auto;
          }

          > .col-12.text-right {
            width: 50%;
            margin: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .login-body {
    height: 100%;

    .login-content {
      width: 100%;
      flex-direction: row;

      .login-panel {
        width: 100% !important;
        overflow: none;
        padding: 3.5rem;

        .login-panel-content {
          width: 100%;
        }
      }

      .login-panel-sifa {
        width: 100% !important;
        overflow: none;
        padding: 3.5rem;

        .login-panel-content {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .w-260 {
    width: calc(40vw) !important;
  }

  .tab-editor {
    display: flex !important;
  }

  .editor.table-data {
    width: calc(100vw - 130px);
  }

  .data-table,
  .preview-app {
    height: calc(100vh - 214px) !important;
    width: calc(100vw - 80px);
  }

  .page-option {
    &.p-sidebar {
      right: 0;
      left: 80px;
      top: 170px;
      height: calc(100vh - 269px);
    }
  }

  .sticky-phone {
    background: transparent;

    .menu-subtitle {
      display: none;
    }
  }

  .tab-editor {
    + .app-container {
      .p-vmenu {
        p-tabmenu {
          .p-tabmenu-nav {
            height: calc(100vh - 17.75rem);
          }
        }
      }
    }
  }

  .project-properties {
    width: calc(100vw);
    right: 0;
  }

  .sub-header {
    .p-toolbar.p-component {
      z-index: 20;
    }
  }
}

@media only screen and (max-width: 541px) {

  .layout-app {
    margin-top: 40px;
  }

  .top-65 {
    top: 80px !important;
  }

  .data-table,
  .preview-app {
    height: calc(100vh - 253px) !important;
  }

  .page-option {
    &.p-sidebar {
      top: 210px;
      width: calc(100vw - 80px);
      height: calc(100vh - 309px);
    }

    .p-card .p-card-body .p-card-footer {
      width: calc(100vw - 80px);
    }
  }

  .content-box {
    margin-left: 50px;
    width: calc(100vw - 50px);
  }

  .remote {
    width: 75px;
    height: 100px;
    margin-bottom: -100px;
  }

  .right-toolbar {
    margin-bottom: 50px;
  }

  .tab-editor {
    + .app-container {
      .p-vmenu {
        p-tabmenu {
          .p-tabmenu-nav {
            height: calc(100vh - 11.0833rem);
          }
        }
      }
    }
  }

  .sub-header {
    &.editor {
      .p-toolbar.p-component {
        height: 94px;
      }
    }
  }

  .project-properties {
    top: 150px;
    height: calc(100vh - 16.1em);
  }

  .tabview-standard.tab-sticky-modal .p-tabview-panels {
    max-height: calc(100vh - 390px);
  }

  .summary-card {
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .footer {
    .p-footer {
      justify-content: center;
    }
  }
}

@media (max-width: 490px) {
  .display-user {
    display: none !important;
  }

  // .project-dataview .p-dataview-content {
  //   height: 650px;
  // }
}

// @media (max-width: 426px) {
//   .preview-content {
//     display: flex !important;
//   }
//   .p-header, .sub-header, .tab-editor, .data-content, .p-vmenu,
//   .sticky-phone>.grid:first-child, .footer, .smartphone:before, .iphone:before {
//     display: none !important;
//   }
//   .layout-app {
//     margin: 0;
//   }
//   .preview-app {
//     width: 100vw !important;
//     height: calc(100vh - 56px) !important;
//     padding: 0 !important;
//   }
//   .sticky-phone {
//     position: initial;
//   }
//   .smartphone, .iphone {
//     position: unset;
//     width: unset;
//     height: unset;
//     margin: unset;
//     border: unset;
//     border-radius: unset;
//     background: unset;
//   }
//   .mobile-bottommenu {
//     position: fixed;
//     border-radius: unset;
//     left: 0;
//   }
// }
